import { NO_DIALOG_PARAMS } from './dialog/_constants'
import type { DialogParams } from './dialog/_types'
import { createContext, Dispatch, ReactNode, useReducer } from 'react'
import { Dialog } from './dialog'
import { changeDialogParams } from './reducer/change-dialog-params'
import { initDialogParams } from './reducer/init-dialog-params'

export const DispatchNewParamsContext = createContext<Dispatch<DialogParams>>(
  () => NO_DIALOG_PARAMS,
)

type Props = {
  children: ReactNode
}

/**
 * This Component wraps the Dialog component and the dispatch function.
 * Use it in the page, and wrap the DeepTreeComponentDependsOnThisDialog in it:
 * ```jsx
 * <DialogProvider>
 *   <DeepTreeComponentDependsOnThisDialog>
 * </DialogProvider>
 * ```
 * And in the DeepTreeComponentDependsOnThisDialog:
 * ```js
 * const dispatch = useContext(DispatchNewParamsContext)
 * dispatch(NO_DIALOG_PARAMS) // close the dialog
 * ```
 */
export function DialogProvider({ children }: Props): JSX.Element {
  const [dialogParams, dispatchNewParams] = useReducer(
    changeDialogParams,
    undefined,
    initDialogParams,
  )

  return (
    <DispatchNewParamsContext.Provider value={dispatchNewParams}>
      {children}
      <Dialog dialogParams={dialogParams} />
    </DispatchNewParamsContext.Provider>
  )
}
