import { APIDETAILS } from '../../_utilities/environment/api.url.details'
import fbAuth from '../../firebase'
import { HttpRequestMethod } from '../../_types'
import { NETWORK_ERROR, UNAUTHENTICATED_ERROR, UNSUPPORTED_CODE_ERROR } from '../../_constants'
import type { ErrorMessage } from '../../_types'
import { MarketingOptInInterface } from '@shared/interfaces/creators/marketing-opt-in.interface'

export default async function setMarketingOptInByChannelId(
  channelId: string,
  isSubscribed: boolean,
): Promise<MarketingOptInInterface> {
  const token = await fbAuth.currentUser?.getIdToken(true)
  if (!token) {
    return Promise.reject(UNAUTHENTICATED_ERROR)
  }

  const url = `${APIDETAILS.creatorsUrl}/marketing/opt-in`

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const body = JSON.stringify({
    channelId,
    isSubscribed,
  })

  try {
    const response = await fetch(url, {
      headers,
      body,
      method: HttpRequestMethod.PUT,
    })

    switch (response.status) {
      case 200: {
        return await response.json()
      }
      case 401:
      case 500: {
        const error: ErrorMessage = await response.json()
        console.log(error)
        return Promise.reject(error)
      }
      default: {
        return Promise.reject(UNSUPPORTED_CODE_ERROR)
      }
    }
  } catch (networkError) {
    return Promise.reject(NETWORK_ERROR)
  }
}
