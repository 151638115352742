import { toDollar } from '../../_utilities/to-dollar'
import { toDisplayCurrency } from '../../_utilities/to-display-currency'
import style from './common.module.css'
import type { CSSProperties, ReactElement } from 'react'
import { useAppSelector as useSel } from '@root/store'
import {
  selectCodeForCouponValidation,
  selectCouponValidationResponse,
} from '@store/coupons-slice/_selectors'
import { useEffect, useState } from 'react'
import { isGreaterThanZero } from '@utils/connascence'
import RenderIf from '@utils/render-if'
import { isEmptyString } from '@utils/strings'

export type Props = {
  amount: number
  vidzingFee: number
  total: number
  currency: string
  checkoutTitle: string
  className?: string
  style?: CSSProperties
}

export function SplitPrice({
  amount,
  vidzingFee,
  total,
  currency,
  checkoutTitle,
  className = style.defaultClassName,
  style: inlineStyle,
}: Readonly<Props>): ReactElement {
  // Constants
  const formattedCurrency = toDisplayCurrency(currency)
  const totalDisplay = total === 0 ? 'FREE' : formattedCurrency + toDollar(total)
  const serviceFeeDisplay = formattedCurrency + toDollar(vidzingFee)

  // States
  const [totalToPay, setTotalToPay] = useState('')
  const [serviceFeeToPay, setServiceFeeToPay] = useState('')
  const [couponDiscountAmount, setCouponDiscountAmount] = useState('')

  // Selectors
  const couponCodeUsed = useSel(selectCodeForCouponValidation)
  const couponResponse = useSel(selectCouponValidationResponse)

  // Coupon data
  const { isValid, data } = couponResponse || {}

  // Data could be empty if the coupon is invalid so we need to check if it exists before accessing the properties
  const discountAmountInCents = data?.discountAmountInCents
  const serviceFeeInCents = data?.serviceFeeInCents
  const totalAmountToPayInCents = data?.totalAmountToPayInCents

  // Effects
  useEffect(() => {
    if (isValid && !isNaN(totalAmountToPayInCents)) {
      // Only apply these changes if the coupon is valid and the value is a number
      setTotalToPay(
        isGreaterThanZero(totalAmountToPayInCents)
          ? formattedCurrency + toDollar(totalAmountToPayInCents)
          : 'FREE',
      )
    }
  }, [
    couponResponse,
    couponCodeUsed,
    serviceFeeInCents,
    isValid,
    formattedCurrency,
    totalAmountToPayInCents,
  ])

  useEffect(() => {
    if (isValid && !isNaN(serviceFeeInCents)) {
      // Only apply these changes if the coupon is valid and the value is a number
      setServiceFeeToPay(
        isGreaterThanZero(serviceFeeInCents)
          ? formattedCurrency + toDollar(serviceFeeInCents)
          : formattedCurrency + toDollar(0),
      )
    }
  }, [serviceFeeInCents, isValid, formattedCurrency])

  useEffect(() => {
    if (isValid && !isNaN(discountAmountInCents)) {
      // Only apply these changes if the coupon is valid and the value is a number
      setCouponDiscountAmount(formattedCurrency + toDollar(discountAmountInCents))
    }
  }, [discountAmountInCents, formattedCurrency, isValid])

  return (
    <div className={[style.wrapper, className].join(' ')} style={inlineStyle}>
      <div className={style.itemRow}>
        <span>{checkoutTitle}</span>
        <span>{formattedCurrency + toDollar(amount)}</span>
      </div>
      <div className={style.itemRow}>
        <span>+ Service Fee</span>
        <span>{!isEmptyString(serviceFeeToPay) ? serviceFeeToPay : serviceFeeDisplay}</span>
      </div>
      <RenderIf isTrue={isValid}>
        <div className={style.itemRow}>
          <span>- {couponCodeUsed}</span>
          <span>{couponDiscountAmount}</span>
        </div>
      </RenderIf>
      <hr className={style.hr} />
      <div className={style.totalRow}>
        <span>Total</span>
        <span>{!isEmptyString(totalToPay) ? totalToPay : totalDisplay}</span>
      </div>
    </div>
  )
}
