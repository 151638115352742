import style from './common.module.scss'
import { CheckoutDetails } from './checkout-details'
import { Thumbnail } from '@library/_content'
import { DIALOG_WIDTH_THUMB } from '@root/constants'
import { ReactElement } from 'react'

export type Props = {
  contentId: string
  channelPathname: string
}

export function TicketBox({ contentId, channelPathname }: Readonly<Props>): ReactElement {
  return (
    <div className={style.content}>
      <Thumbnail className={style.thumbnail} contentId={contentId} width={DIALOG_WIDTH_THUMB} />
      <div className={style.details}>
        <CheckoutDetails contentId={contentId} channelPathname={channelPathname} />
      </div>
    </div>
  )
}
