import styles from './styles.module.scss'
import { useAppSelector } from '@root/store'
import {
  selectChannelPassById,
  selectChannelThemeByChannelPathname,
} from '@store/channel-slice/_selectors'
import { ONE_TIME_PAYMENT, PASS_ACCESS_ALL } from '@features/_ui/pass-terms/_constants'
import { ReactNode } from 'react'
import TickIcon from '@features/_ui/icons/tick'

interface Props {
  channelPathname: string
  passId: string
  variant: 'panel' | 'dialog'
}

export default function PassTerms({ channelPathname, passId, variant }: Props): JSX.Element {
  const pass = useAppSelector(selectChannelPassById(channelPathname, passId))
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))

  const renderIcon = (): ReactNode => {
    return variant === 'panel' ? <TickIcon fill={channelTheme?.buttonColour} /> : <span>•</span>
  }

  const renderPassAccessString = (): string => {
    if (variant === 'panel') {
      return PASS_ACCESS_ALL
    }

    return `${PASS_ACCESS_ALL} for ${pass?.durationInDays} days`
  }

  return (
    <div className={styles.wrapper}>
      <ul>
        <li>
          {renderIcon()}
          {ONE_TIME_PAYMENT}
        </li>
        <li>
          {renderIcon()}
          {renderPassAccessString()}
        </li>
      </ul>
    </div>
  )
}
