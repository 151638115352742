import { Temporal } from '@js-temporal/polyfill'
import { NINETY_NINE_DAYS_IN_SECONDS, ONE, ONE_DAY_IN_SECONDS } from '../../constants'
import SmallestUnit = Temporal.SmallestUnit
import LargestUnit = Temporal.LargestUnit

/**
 * Function to take duration in seconds and humanize to days, hours or minutes
 * @param durationInSeconds
 */
export function formatLongDuration(durationInSeconds: number): string {
  // Get duration
  const duration = Temporal.Duration.from({ seconds: durationInSeconds }).round({
    relativeTo: Temporal.Now.plainDateISO(),
    largestUnit: getLargestUnitFromDurationInSeconds(durationInSeconds),
    smallestUnit: getSmallestUnitFromDurationInSeconds(durationInSeconds),
  })

  if (duration.years > ONE) {
    return `${duration.years} years`
  } else if (duration.years === ONE) {
    return '1 year'
  } else if (duration.months > ONE) {
    return `${duration.months} months`
  } else if (duration.days > ONE) {
    return `${duration.days} days`
  } else if (duration.days === ONE) {
    return '1 day'
  } else if (duration.hours >= 2) {
    return `${duration.hours} hours`
  } else {
    const minutes = duration.hours * 60 + duration.minutes
    return `${minutes} minutes`
  }
}

function getLargestUnitFromDurationInSeconds(durationInSeconds: number): LargestUnit<any> {
  if (durationInSeconds >= NINETY_NINE_DAYS_IN_SECONDS) {
    return 'years'
  } else {
    return 'days'
  }
}

function getSmallestUnitFromDurationInSeconds(durationInSeconds: number): SmallestUnit<any> {
  if (durationInSeconds >= ONE_DAY_IN_SECONDS) {
    return 'days'
  } else {
    return 'minutes'
  }
}
