import { useAppSelector } from '@root/store'
import { selectChannelThemeByChannelPathname } from '@store/channel-slice/_selectors'
import useGetSignupUrl from '@hooks/use-get-signup-url'
import Hyperlink from '@ui/cta/hyperlink'
import { ReactElement } from 'react'

export interface Props {
  channelPathname: string
}
export function ToSignUpButton({ channelPathname }: Props): ReactElement {
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))

  // Get the sign-up Url from hook in case channel is white label
  const { signupUrl, hasWhiteLabel } = useGetSignupUrl(channelPathname)
  return (
    <Hyperlink
      label={'Sign up'}
      path={signupUrl}
      variant={'primary'}
      external={false}
      channelTheme={channelTheme}
    />
  )
}
