import type { ReactElement, ReactNode } from 'react'
import { SplitPrice } from '../../../split-price/split-price-fee'
import style from './common.module.css'
import { selectContentPricesByContentId } from '@store/contents-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import { FREE_TAG } from '@root/constants'
import { SplitPriceNoFee } from '@library/split-price/split-price-no-fee'
import { isEqualToOne, isEqualToZero } from '@root/utils/connascence'
import { useCalculateFees } from '@library/_flows/_checkout-video-flow/_hooks/use-calculate-fees'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'

export type FeeDisplayTypeToShow = 'free' | 'fee' | 'noFee'

export type Props = {
  contentId: string
  className?: string
  channelPathname: string
}

export default function CheckoutSplitPrice({
  contentId,
  className,
  channelPathname,
}: Readonly<Props>): ReactElement {
  const channelFeeDisplayType =
    useSel(selectChannelPropByChannelPathname(channelPathname)('channelFeeDisplayType')) ??
    'exclusive'

  const contentPrices = useSel(selectContentPricesByContentId(contentId))
  const contentPricesCount = contentPrices.length

  const priceTotals = useCalculateFees(contentPrices[0].price, channelPathname)

  let feeDisplayType: FeeDisplayTypeToShow = 'fee'
  // If free
  if (isEqualToOne(contentPricesCount) && isEqualToZero(contentPrices[0].price)) {
    feeDisplayType = 'free'
  } else if (channelFeeDisplayType === 'inclusive') {
    feeDisplayType = 'noFee'
  }

  const componentMap: Record<FeeDisplayTypeToShow, ReactNode> = {
    free: (
      <>
        <div className={style.type}>{contentPrices[0].label}</div>
        <div className={style.price}>{FREE_TAG}</div>
      </>
    ),
    fee: (
      <SplitPrice
        amount={contentPrices[0].price}
        vidzingFee={priceTotals.fee}
        total={priceTotals.total}
        currency={contentPrices[0].currency}
        checkoutTitle={contentPrices[0].label}
      />
    ),
    noFee: (
      <SplitPriceNoFee
        checkoutTitle={'Price'}
        total={contentPrices[0].price}
        currency={contentPrices[0].currency}
      />
    ),
  }

  return <div className={[style.wrapper, className].join(' ')}>{componentMap[feeDisplayType]}</div>
}
