import style from './common.module.css'
import type { KeyboardEvent, MouseEvent, ReactNode } from 'react'

export type Props = {
  children: ReactNode

  className: string
}

export function DialogContentBox({ children, className }: Props): JSX.Element {
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  return (
    <div
      className={[style.dialogContentBox, className].join(' ')}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={-1}
    >
      {children}
    </div>
  )
}
