import { ContentScheduleState } from '@root/utils/content-schedule-state'
import { formatLongDuration } from '@root/utils/format-long-duration'
import {
  addSecondsToCurrentDate,
  convertDaysToSeconds,
  secondsIsGreaterThanDays,
} from '@root/utils/connascence'
import {
  VOD_AVAILABLE_TO_WATCH_PREFIX,
  VOD_ACCESS_TO_WATCH_SUFFIX,
  VOD_DAYS_WHEN_REPLAY_STRINGS_CHANGE,
} from '@root/utils/window-access/constants'
import { Temporal } from '@js-temporal/polyfill'
import { dmyFormatter, hmtFormatter } from '@root/utils/date-time-formatters'
import { ContentState } from '@apis/contents-microservice/_entities/_types'
import { youHaveCountdownLeftToWatchString } from '@root/utils/window-access/common-window-access'

interface Props {
  contentState: ContentState
  state: ContentScheduleState
  availabilityDuration?: number
  expiryDate?: string
}
/**
 * Function to return the window access strings for vod content
 * Handles all possible content schedule state and user content state
 * @param contentState
 * @param state
 * @param expiryDate
 * @param availabilityDuration
 */
export function getVodBundleAudioWindowAccessString({
  contentState,
  state,
  availabilityDuration,
  expiryDate,
}: Props): string {
  // Pre-sale window access string
  const getPreSaleWindowAccessString = (): string => {
    switch (contentState.button) {
      case 'findOutMore':
      case 'subscribe':
      case 'ticketedOnly':
      case 'bookNow':
      case 'buyNow':
        return noAccessWatchString(availabilityDuration, expiryDate)
      case 'subscribed':
      case 'booked':
      case 'purchased':
      case 'watchNow':
      case 'listenNow':
        return hasAccessPreSaleWatchString(
          contentState.access.expiresIn,
          availabilityDuration,
          expiryDate,
        )
      default:
        return null
    }
  }

  // Live window access string
  const getLiveWindowAccessString = (): string => {
    switch (contentState.button) {
      case 'findOutMore':
      case 'subscribe':
      case 'ticketedOnly':
      case 'bookNow':
      case 'buyNow':
        return noAccessWatchString(availabilityDuration, expiryDate)
      case 'subscribed':
      case 'booked':
      case 'purchased':
      case 'watchNow':
      case 'listenNow':
        return hasAccessWatchString(contentState.access.expiresIn)
      default:
        return null
    }
  }

  switch (state) {
    case 'pre-sale':
      return getPreSaleWindowAccessString()
    case 'live':
      return getLiveWindowAccessString()
    case 'expired':
      return null
  }
}

/**
 * Get the vod no access watch string
 * @param availabilityDuration
 * @param expiryDate
 */
export function noAccessWatchString(availabilityDuration?: number, expiryDate?: string): string {
  if (availabilityDuration) {
    return accessToWatchString(availabilityDuration, 'days')
  } else if (expiryDate) {
    return availableToWatchString(expiryDate)
  }
}

/**
 * Function to return vod has access but in pre-sale watch string
 * @param accessExpiresInSeconds
 * @param availabilityDuration
 * @param expiryDate
 */
export function hasAccessPreSaleWatchString(
  accessExpiresInSeconds: number,
  availabilityDuration: number,
  expiryDate: string,
): string {
  if (availabilityDuration) {
    const endDateString = addSecondsToCurrentDate(accessExpiresInSeconds).toISOString()
    return availableToWatchString(endDateString)
  } else if (expiryDate) {
    return availableToWatchString(expiryDate)
  }
}

/**
 * Function to return the correct vod purchased watch string
 * if days are greater than 30 then show watch until end date
 * otherwise show you have countdown left to watch
 * @param accessExpiresInSeconds
 */
export function hasAccessWatchString(accessExpiresInSeconds: number): string {
  if (secondsIsGreaterThanDays(accessExpiresInSeconds, VOD_DAYS_WHEN_REPLAY_STRINGS_CHANGE)) {
    const endDateString = addSecondsToCurrentDate(accessExpiresInSeconds).toISOString()
    return availableToWatchString(endDateString)
  }
  return youHaveCountdownLeftToWatchString(accessExpiresInSeconds)
}

/**
 * Create the available to watch until end date string e.g.
 * Available to watch until 10 Jun 2022 7:30pm NZDT
 * @param dateString
 */
function availableToWatchString(dateString: string): string {
  const temporalDate = Temporal.Instant.from(dateString)
  return (
    VOD_AVAILABLE_TO_WATCH_PREFIX +
    dmyFormatter.format(temporalDate) +
    ' ' +
    hmtFormatter.format(temporalDate)
  )
}

/**
 * Create the window access to watch string e.g.
 * 90 days access to watch
 * @param duration
 * @param durationType
 */
function accessToWatchString(duration: number, durationType: 'seconds' | 'days'): string {
  const durationInSeconds = (duration): number => {
    switch (durationType) {
      case 'seconds':
        return duration
      case 'days':
        return convertDaysToSeconds(duration)
    }
  }
  return formatLongDuration(durationInSeconds(duration)) + VOD_ACCESS_TO_WATCH_SUFFIX
}
