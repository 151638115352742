import { ToLoginLink } from '@library/_flows/_auth-flow/to-login-link'
import { ToSignUpButton } from '@library/_flows/_auth-flow/to-sign-up-button'
import style from './styles.module.scss'
import UseSelectWhiteLabelData from '@hooks/white-label/use-select-white-label-data'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import { useAppSelector } from '@root/store'
import { getOrganisationByOrgId } from '@store/organisation-slice/_selectors'
import { useMemo } from 'react'

export type AccessType = 'channel' | 'content'
export interface Props {
  channelPathname: string
  accessType: AccessType
}
export function AuthButtonBox({ channelPathname, accessType }: Props): JSX.Element {
  // Get white label for removing disclaimer
  const { whiteLabel, organisationIdFromChannel } = UseSelectWhiteLabelData()
  const isWhiteLabel = !!whiteLabel

  // Channel name to use if white labelled
  const channelName =
    useAppSelector(selectChannelPropByChannelPathname(channelPathname)('displayName')) ?? ''

  const organisation = useAppSelector(getOrganisationByOrgId(organisationIdFromChannel))

  const accessTypeMap: Record<AccessType, string> = {
    channel: 'content',
    content: 'this content',
  }

  const disclaimer = useMemo(() => {
    return `Please sign up for a ${
      isWhiteLabel ? organisation?.name || channelName : 'Vidzing'
    } account
      to access ${accessTypeMap[accessType]}`
  }, [isWhiteLabel, organisation, channelName])

  return (
    <div className={style.wrapper}>
      <p className={style.loginLink}>
        Already have an account? <ToLoginLink channelPathname={channelPathname} />
      </p>
      <div className={style.signup}>
        <ToSignUpButton channelPathname={channelPathname} />
      </div>
    </div>
  )
}
