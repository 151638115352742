import { ReactElement, useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import { InputField } from '@library/_form/input-field'
import Button from '@ui/cta/button'
import { useDispatch } from 'react-redux'
import { ProductTypes, ValidateCouponPayload } from '@store/coupons-slice/interfaces'
import { validateCouponThunk } from '@store/coupons-slice/_thunks'
import { useAppSelector as useSel } from '@root/store'
import {
  selectChannelPropByChannelPathname,
  selectChannelThemeByChannelPathname,
  selectPassByPassId,
  selectSubscriptionBySubscriptionId,
} from '@store/channel-slice/_selectors'
import {
  selectCouponValidationResponse,
  selectCouponValidationStatus,
} from '@store/coupons-slice/_selectors'
import { clsx } from 'clsx'
import { useCheckoutContext } from '@providers/checkout-provider/_contexts'
import { isEmptyString, removeWhitespace } from '@utils/strings'
import { selectContentById } from '@store/contents-slice/_selectors'
import { isEmptyArray } from '@utils/arrays'

interface Props {
  productId: string // Can be of either Content/Subscription/Pass ID
  channelPathname: string
  productType?: ProductTypes
}

export default function ApplyCouponBox({
  productId,
  channelPathname,
  productType = ProductTypes.CONTENT,
}: Readonly<Props>): ReactElement {
  const dispatch = useDispatch()

  // Context
  const { flexiPrice } = useCheckoutContext()

  // Local States
  const [promotionCode, setPromotionCode] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)
  const [couponFailed, setCouponFailed] = useState(false)
  const [failedText, setFailedText] = useState('')

  // Selectors
  const channelId = useSel(selectChannelPropByChannelPathname(channelPathname)('channelId'))
  const organisation = useSel(selectChannelPropByChannelPathname(channelPathname)('organisation'))
  const couponStatus = useSel(selectCouponValidationStatus)
  const couponResponse = useSel(selectCouponValidationResponse)
  // The productId can be an ID of either Content/Subscription/Pass so we need to check all three
  const content = useSel(selectContentById(productId))
  const subscription = useSel(selectSubscriptionBySubscriptionId(channelPathname, productId))
  const pass = useSel(selectPassByPassId(channelPathname, productId))
  const channelTheme = useSel(selectChannelThemeByChannelPathname(channelPathname))

  // Effects
  useMemo(() => {
    // Update the button loading
    if (buttonLoading && couponStatus !== 'idle') {
      setButtonLoading(false)
    }

    // Handle error. I set this one inside the useMemo because you don't need the code in the whole component.
    const handleError = (message?: string) => {
      setCouponFailed(true)

      // TS is going to complain about this validation, that is why I have included !!message
      if (!isEmptyString(message) && !!message) {
        setFailedText(message)
      }
    }

    // Using strict comparison to check if the coupon is invalid since the response can be null
    if (couponStatus === 'failed')
      return handleError('This coupon code doesn’t exist. Please, try again.')

    // Coupon can't be redeemed on this product type
    if (couponResponse?.isValid === false)
      return handleError(
        `This coupon code exists, but can't be used on this product. Please, try again.`,
      )

    // Reset the coupon code if the coupon is valid
    if (couponStatus === 'succeeded' && couponResponse?.isValid) {
      setPromotionCode('')
      setCouponFailed(false)
    }
  }, [buttonLoading, couponResponse, couponStatus])

  useEffect(() => {
    if (couponFailed && promotionCode) {
      setCouponFailed(false)
      setFailedText('')
    }
  }, [promotionCode])

  // Handles applying coupon to the content if the coupon is valid.
  function handleApplyCouponClick() {
    setButtonLoading(true)

    // Determine what currency to use for the coupon validation
    // (Since the currency can be from the content, pass, or subscription from the productId sent to this component)
    const currency = !isEmptyArray(content?.contentPrices)
      ? content?.contentPrices[0]?.currency
      : pass?.currency || subscription?.currency

    // Determine what price to use for the coupon validation
    // (Since the price can be from the content, pass, or subscription from the productId sent to this component)
    const price = !isEmptyArray(content?.contentPrices)
      ? content?.contentPrices[0]?.price
      : pass?.price || subscription?.price

    // Payload to validate the coupon
    const payload: ValidateCouponPayload = {
      productId,
      organisationId: organisation?.organisationId,
      channelId,
      promotionCode: removeWhitespace(promotionCode),
      productType,
      currency,
      price,
    }

    // If the flexiPrice is available, send it in the payload.
    if (flexiPrice) {
      payload.price = flexiPrice
    }

    // Send an action to validate if the coupon is valid. (This hits the BE endpoint "/validate" on Billing MS)
    dispatch(validateCouponThunk(payload))
  }

  const cssVariables: Record<string, unknown> = {
    '--btnColour': channelTheme?.buttonColour ?? 'var(--vidzingEarth)',
  }

  // This component is using clsx to apply styles!
  // Never used clsx before? Check out the documentation here: https://www.npmjs.com/package/clsx
  return (
    <div className={styles.wrapper} style={cssVariables}>
      <div className={clsx(styles.inputBox, { [styles.invalidCoupon]: couponFailed })}>
        <InputField
          setValue={setPromotionCode}
          type={'text'}
          value={promotionCode}
          placeholder={'Have a discount?'}
        />
        <span className={couponFailed ? styles.invalidCoupon : 'hide'}>{failedText}</span>
      </div>
      <Button
        label={'Apply'}
        variant={'secondary'}
        onClick={handleApplyCouponClick}
        loading={buttonLoading}
        channelTheme={channelTheme}
      />
    </div>
  )
}
