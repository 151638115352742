import setMarketingOptInByChannelId from '@apis/channels-microservice/set-marketing-opt-in-by-channel-id'
import Button from '@ui/cta/button'
import {
  selectChannelPropByChannelPathname,
  selectChannelThemeByChannelPathname,
  selectPassDataForEventTracking,
} from '@store/channel-slice/_selectors'
import { stripePromise } from '@apis/stripe'
import { useAppSelector } from '@root/store'
import { ReactElement, useState } from 'react'
import axiosRequest from '@apis/axios'
import { PassCheckoutResponse } from '@apis/billing-microservice/_entities/_types'
import { TrackingEventsEnum } from '@shared/enums/tracking-events.enum'
import { trackEvent } from '@utils/event-tracking'
import { getReturnUrl } from '@utils/stripe/return-url'
import { useCheckoutContext } from '@providers/checkout-provider/_contexts'
import { useDispatch, useSelector } from 'react-redux'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { selectCodeForCouponValidation } from '@store/coupons-slice/_selectors'
import { getChannelPassStatusThunk } from '@store/pass-slice/_thunks'
import { getChannelThunk } from '@store/channel-slice'
import { selectEmail, selectUserId } from '@store/user-slice/_selectors'
import { getUserVizingDataThunk } from '@store/user-slice/_thunk'
import { getAllRailsThunk } from '@store/rails-slice/_thunks'

export type Props = {
  channelPathname: string
  passId: string
  hideDialog: () => void
}

export function PassButton({ channelPathname, passId, hideDialog }: Readonly<Props>): ReactElement {
  // Dispatch
  const dispatch = useDispatch()

  // Context
  const { isJoinMailingList } = useCheckoutContext()

  // Local States
  const [isLoading, setIsLoading] = useState(false)

  // Selectors
  const channelId = useAppSelector(selectChannelPropByChannelPathname(channelPathname)('channelId'))
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))
  const eventTrackingData = useAppSelector(
    selectPassDataForEventTracking({ passId, channelPathname }),
  ) // Get the event tracking data for the event tracking trigger
  const promotionCode = useAppSelector(selectCodeForCouponValidation)

  const userEmail = useAppSelector(selectEmail)

  // Flags
  const isCouponsForViewersActive = useSelector(
    selectAppFeatureFlagStatusByName('flagCouponsForViewers'),
  )

  const subscribe = async () => {
    setIsLoading(true)

    // Trigger tracking event
    trackEvent(TrackingEventsEnum.INITIATE_CHECKOUT, eventTrackingData)

    if (isJoinMailingList) {
      await setMarketingOptInByChannelId(channelId, true)
    }

    const checkoutResult = (await axiosRequest(
      'billing',
      {
        method: 'POST',
        url: 'pass-checkout',
        data: {
          channelId,
          passId,
          returnUrl: getReturnUrl(window.location.href),
          promotionCode,
        },
      },
      true,
      // Send the version 2 of the endpoint if the flag isCouponsForViewersActive is true
      isCouponsForViewersActive ? '2' : '1',
    )) as Partial<PassCheckoutResponse>

    if (checkoutResult.sessionId) {
      // Hide dialog and redirect to checkout:
      hideDialog()
      const stripe = await stripePromise
      const result = await stripe?.redirectToCheckout({ sessionId: checkoutResult.sessionId })
      if (result?.error) {
        setIsLoading(false)
        throw result?.error
      }
    }

    if (checkoutResult.status === 'active') {
      dispatch(getAllRailsThunk({ channelPathname }))
      dispatch(getChannelPassStatusThunk({ channelId, passIds: [passId], channelPathname }))
    }

    setIsLoading(false)
  }

  return (
    <Button
      label={'Checkout'}
      loading={isLoading}
      onClick={subscribe}
      channelTheme={channelTheme}
      variant={'primary'}
    />
  )
}
