import style from './common.module.scss'
import SubscriptionTerms from '@features/_ui/subscription-terms/index.'
import { useAppSelector } from '@root/store'
import { selectSubscriptionDataBySubscriptionId } from '@store/channel-slice/_selectors'
import RenderIf from '@utils/render-if'
import ApplyCouponBox from '@components/dialog-provider/dialog/checkout-dialog-content/ticket-box/checkout-details/apply-coupon-box'
import { ProductTypes } from '@store/coupons-slice/interfaces'
import { ReactElement, useEffect, useState } from 'react'
import {
  selectCodeForCouponValidation,
  selectCouponValidationResponse,
} from '@store/coupons-slice/_selectors'
import { toDollar } from '@library/_utilities/to-dollar'
import {
  getCurrencyFromSubscriptionPriceString,
  formatTotalToPay,
} from 'utils/subscription-util-functions'
import { isGreaterThanZero } from '@utils/connascence'
import { isEmptyString } from '@utils/strings'

export type Props = {
  channelPathname: string
  subscriptionId: string
}

export function SubscribeDetails({
  channelPathname,
  subscriptionId,
}: Readonly<Props>): ReactElement {
  // Selectors
  const subscriptionData = useAppSelector(
    selectSubscriptionDataBySubscriptionId(channelPathname, subscriptionId),
  )
  const couponCodeUsed = useAppSelector(selectCodeForCouponValidation)
  const couponResponse = useAppSelector(selectCouponValidationResponse)
  const formattedCurrency = getCurrencyFromSubscriptionPriceString(subscriptionData?.price)

  // Local States
  const [totalToPay, setTotalToPay] = useState(subscriptionData?.price)

  // Coupon data
  const { isValid, data } = couponResponse || {}

  // Data could be empty if the coupon is invalid, so we need to check if it exists before accessing the properties
  const discountAmountInCents = data?.discountAmountInCents
  const totalAmountToPayInCents = data?.totalAmountToPayInCents

  // Effects
  useEffect(() => {
    if (isValid) {
      if (isGreaterThanZero(totalAmountToPayInCents)) {
        setTotalToPay(
          formatTotalToPay({
            interval: subscriptionData?.interval,
            totalAmountToPayInCents,
            formattedCurrency,
          }),
        )
      } else {
        setTotalToPay('FREE')
      }
    }
  }, [totalAmountToPayInCents, formattedCurrency, isValid, subscriptionData])

  return (
    <div className={style.wrapper}>
      <h3 className={style.h3}>{subscriptionData?.title}</h3>
      <SubscriptionTerms
        channelPathname={channelPathname}
        subscriptionId={subscriptionId}
        variant={'dialog'}
      />
      <hr />
      <div className={style.priceWrapper}>
        <h4>Subscription</h4>
        <span>{subscriptionData?.price}</span>
      </div>
      <RenderIf isTrue={isValid}>
        <div className={style.itemRow}>
          <span>- {couponCodeUsed}</span>
          <span>{formattedCurrency + toDollar(discountAmountInCents)}</span>
        </div>
      </RenderIf>
      <RenderIf isTrue={!isEmptyString(couponCodeUsed)}>
        <hr />
        <div className={style.priceWrapper}>
          <span>Total</span>
          <span>{!isEmptyString(totalToPay) ? totalToPay : subscriptionData?.price}</span>
        </div>
      </RenderIf>
      <ApplyCouponBox
        productId={subscriptionId}
        channelPathname={channelPathname}
        productType={ProductTypes.SUBSCRIPTION}
      />
    </div>
  )
}
