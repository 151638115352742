import type { ContentType } from '@apis/contents-microservice/_entities/_types'
import { setButtonState } from '@store/contents-slice'
import {
  selectContentPropByContentId as selContent,
  selectContentStateForContentById,
  selectExternalLinkPropByContentId as selExternalLink,
  selectLivePropByContentId as selLive,
} from '@store/contents-slice/_selectors'
import { INT_MAX, ONE_SECOND_MS, TWENTY_MINS_MS, ZERO_MS } from '@root/constants'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector as useSel } from '@store/index'
import { isAfter, isBefore, addSeconds } from 'date-fns'

// TODO: useCheckoutVideoFlowState should be called after fetch the state.
//  Carefully design when integrate with the new API and save some timers.

/**
 * The ToCheckoutButton and ToVideoInfoButton should change to watchNow
 * 5 minutes before the live start.
 * This function calculate the time different between the current time and
 * the above change moment.
 * @param startTimeString
 */
function calculateWatchNowTimerDelayMs(startTimeString: string) {
  const startTime = new Date(startTimeString)
  const currentTime = new Date()
  const realDelayMs = Math.max(startTime.getTime() - currentTime.getTime(), 0)

  // if a timer delay is too big, it will cause overflow. So use the maximum int value instead.
  return Math.min(realDelayMs, INT_MAX)
}

/**
 * The ToCheckoutButton and ToVideoInfoButton should disappear after videoEndTime plus
 * expireDelayMs.
 * This function calculate the time different between the current time and
 * the above hiding moment.
 * @param expireDelayMs
 * @param startTimeString
 * @param endTimeString
 */
function calculateExpireTimerDelayMs(
  expireDelayMs: number,
  startTimeString?: string,
  endTimeString?: string,
) {
  const startTime = new Date(startTimeString as string)
  const endTime = endTimeString === undefined ? undefined : new Date(endTimeString)

  const currentTime = new Date()
  const expireTime = new Date((endTime ? endTime : startTime).getTime() + expireDelayMs)
  const realDelayMs = Math.max(expireTime.getTime() - currentTime.getTime(), 0)

  // if a timer delay is too big, it will cause overflow. So use the maximum int value instead.
  return Math.min(realDelayMs, INT_MAX)
}

/**
 * This hook updates two states after the corresponding timer time out:
 * 1. watchNow: The ToCheckoutButton and ToVideoInfoButton should change to watchNow
 * 5 minutes before the live start.
 * 2. expired: The ToCheckoutButton and ToVideoInfoButton should disappear
 * after videoEndTime plus expireDelayMs.
 * @param contentId
 */
export function useUpdateUserContentState(contentId: string): void {
  // Common
  const dateNow = new Date()
  const userContentState = useSel(selectContentStateForContentById(contentId))
  const contentType = useSel(selContent(contentId)('contentType')) ?? 'live'
  const firstContentsType = useSel(selContent(contentId)('bundle'))?.bundleContents?.[0]?.content
    .contentType
  const dispatch = useAppDispatch()
  const isExternalLink = contentType === 'external-link' || firstContentsType === 'external-link'
  const isAudio = contentType === 'audio' || firstContentsType === 'audio'
  const isLive = contentType === 'live' || firstContentsType === 'live'
  const userHasAccess =
    userContentState === 'purchased' ||
    userContentState === 'booked' ||
    userContentState === 'subscribed'

  // Live
  const liveScheduledStartTime = useSel(selLive(contentId)('scheduledStartTime'))
  const liveScheduledEndTime = useSel(selLive(contentId)('scheduledEndTime'))
  const liveStartTime = useSel(selLive(contentId)('startTime'))
  const liveEndTime = useSel(selLive(contentId)('endTime'))
  const replayAvailability = useSel(selLive(contentId)('replayAvailability'))
  const isStreamLiveNow = isAfter(dateNow, new Date(liveStartTime))
  const isStreamEnded = isAfter(dateNow, new Date(liveEndTime))
  const isStreamEndedAndReplayAvailable =
    isStreamEnded && isBefore(dateNow, addSeconds(new Date(liveEndTime), replayAvailability))

  // VOD & Bundle
  const scheduledReleaseDate = useSel(selContent(contentId)('scheduledReleaseDate'))
  const scheduledExpiryDate = useSel(selContent(contentId)('scheduledExpiryDate'))

  // External Link
  const externalLinkScheduledStartTime = useSel(selExternalLink(contentId)('scheduledStartTime'))
  const externalLinkScheduledEndTime = useSel(selExternalLink(contentId)('scheduledEndTime'))

  // Normalized start time
  const startTimeStringMap: Record<ContentType, string | undefined> = {
    live: liveScheduledStartTime,
    vod: scheduledReleaseDate,
    audio: scheduledReleaseDate,
    ['external-link']: externalLinkScheduledStartTime,
    bundle: scheduledReleaseDate,
  }
  const startTimeString = startTimeStringMap[contentType]

  // Normalized end time
  const endTimeStringMap: Record<ContentType, string | undefined> = {
    live: liveScheduledEndTime,
    vod: scheduledExpiryDate,
    audio: scheduledExpiryDate,
    ['external-link']: externalLinkScheduledEndTime,
    bundle: scheduledExpiryDate,
  }
  const endTimeString = endTimeStringMap[contentType]

  // Normalized expire delay
  const expireDelayMsMap: Record<ContentType, number> = {
    live: replayAvailability === undefined ? TWENTY_MINS_MS : replayAvailability * ONE_SECOND_MS,
    vod: ZERO_MS,
    audio: ZERO_MS,
    ['external-link']: ZERO_MS,
    bundle: ZERO_MS,
  }
  const expireDelayMs = expireDelayMsMap[contentType]

  const watchButtonState = isAudio ? 'listenNow' : isExternalLink ? 'joinNow' : 'watchNow'
  const skipContentType = ['bundle', 'live']

  useEffect(() => {
    if (isLive && userHasAccess && (isStreamLiveNow || isStreamEndedAndReplayAvailable)) {
      dispatch(
        setButtonState({
          contentId,
          buttonState: watchButtonState,
        }),
      )
    }

    // Change state to watchNow
    if (!skipContentType.includes(contentType) && startTimeString !== undefined && userHasAccess) {
      const delay = calculateWatchNowTimerDelayMs(startTimeString)
      const timer = setTimeout(() => {
        dispatch(
          setButtonState({
            contentId,
            buttonState: watchButtonState,
          }),
        )
      }, delay)

      return () => clearTimeout(timer)
    }

    // Change state to expired
    if (
      endTimeString !== undefined &&
      userContentState !== 'expired' &&
      userContentState !== 'soldOut'
    ) {
      const delay = calculateExpireTimerDelayMs(expireDelayMs, startTimeString, endTimeString)
      const timer = setTimeout(() => {
        dispatch(setButtonState({ contentId, buttonState: 'expired' }))
      }, delay)

      return () => clearTimeout(timer)
    }
  }, [
    contentId,
    userContentState,
    dispatch,
    expireDelayMs,
    startTimeString,
    endTimeString,
    contentType,
  ])
}
