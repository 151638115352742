import styles from './styles.module.scss'
import { useAppSelector } from '@root/store'
import {
  selectChannelThemeByChannelPathname,
  selectSubscriptionDataBySubscriptionId,
} from '@store/channel-slice/_selectors'
import * as subscriptionConstants from '@features/_ui/subscription-terms/_constants'
import { SubscriptionRecurringType } from '@shared/types/subscription.recurring.type'
import { ReactNode } from 'react'
import TickIcon from '@features/_ui/icons/tick'

interface Props {
  channelPathname: string
  subscriptionId: string
  variant: 'panel' | 'dialog'
}

export default function SubscriptionTerms({
  channelPathname,
  subscriptionId,
  variant,
}: Props): JSX.Element {
  const subscription = useAppSelector(
    selectSubscriptionDataBySubscriptionId(channelPathname, subscriptionId),
  )
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))

  /**
   * date-fns format strings for dates. Strings in '' are strings and the letters in the middle
   * are for formatting.
   * e.g. 'on the' do 'of every' passed to the format function = on the 21st of every (do = day, ordinal)
   */
  const recurringFormatMap: Record<SubscriptionRecurringType, string> = {
    day: 'daily',
    week: 'weekly',
    month: 'monthly',
    year: 'yearly',
  }

  const renderIcon = (): ReactNode => {
    return variant === 'panel' ? <TickIcon fill={channelTheme?.buttonColour} /> : <span>•</span>
  }

  return (
    <div className={styles.wrapper}>
      <ul>
        <li>
          {renderIcon()}
          {subscriptionConstants.SUBSCRIPTION_ACCESS_ALL}
        </li>
        <li>
          {renderIcon()}
          {`${subscriptionConstants.SUBSCRIPTION_BILLED_PREFIX} ${
            recurringFormatMap[subscription?.interval ?? 'month']
          }`}
        </li>
        <li>
          {renderIcon()}
          {subscriptionConstants.SUBSCRIPTION_CANCEL_ANYTIME}
        </li>
      </ul>
    </div>
  )
}
