import { useAppSelector } from '@root/store'
import { selectUserId } from '@store/user-slice/_selectors'
import { useEffect } from 'react'
import gtmEvent from '@apis/google-analytics'

export function useGtmPageUser(page: string): void {
  const user = useAppSelector(selectUserId)

  useEffect(() => {
    gtmEvent(page, user ?? '')
  }, [page, user])
}
