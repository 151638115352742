import { contentScheduleState, ContentScheduleState } from 'utils/content-schedule-state'
import { ReactNode } from 'react'
import FormatDateTime from '@features/_ui/format-date-time'
import { dmyFormatter, hmtFormatter } from 'utils/date-time-formatters'
import { formatShortDuration } from 'utils/format-short-duration'
import RelativeDateString from '@features/_ui/content-schedule/relative-date-string'
import Badge from '@ui/badge'
import styles from './style.module.scss'

export interface Props {
  startTime: string
  endTime: string
  duration: number
}
export default function VodAudioSchedule({ startTime, endTime, duration }: Props): JSX.Element {
  // Day, month, year date string
  const dmyDate = (
    <div>
      <FormatDateTime dateTimeString={startTime} formatter={dmyFormatter} />
    </div>
  )
  // Hour, minute, timezone date string
  const hmtDate = <FormatDateTime dateTimeString={startTime} formatter={hmtFormatter} />
  // Duration string
  const durationFormatted = duration ? (
    <RelativeDateString>{formatShortDuration(duration)}</RelativeDateString>
  ) : (
    <></>
  )

  const componentMap: Record<ContentScheduleState, ReactNode> = {
    ['pre-sale']: (
      <div className={styles.available}>
        <Badge variant={'available'} title={'Available'} />
        {dmyDate}
        {hmtDate}
      </div>
    ),
    live: <></>,
    expired: <>{durationFormatted}</>,
    premium: <> </>,
    available: <></>,
  }

  return <>{componentMap[contentScheduleState(startTime, endTime)]}</>
}
