import { useAppSelector as useSel } from '@root/store'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import {
  getTotalPriceFromPrceAndFee,
  getVidzingFeeFromPriceAndFee,
} from '@root/utils/price-calculations'

export interface SplitFeeAmounts {
  fee: number
  total: number
}

export function useCalculateFees(price: number, channelPathname: string): SplitFeeAmounts {
  // Get the organisation from the channel so we can use the organisations vidzingFees
  const organisation = useSel(selectChannelPropByChannelPathname(channelPathname)('organisation'))

  return {
    fee: getVidzingFeeFromPriceAndFee(price, organisation?.vidzingFee),
    total: getTotalPriceFromPrceAndFee(price, organisation?.vidzingFee),
  }
}
