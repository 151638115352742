import type { AppEnvironments } from '../_utilities/environment/api.url.details'
import { datadogLogs } from '@datadog/browser-logs'

export class StripeEnvironmentConfig {
  public appEnv: AppEnvironments

  constructor(environment: AppEnvironments) {
    this.appEnv = environment
  }

  getConfig(): string {
    try {
      let config: string
      if (this.appEnv === 'prod') {
        config =
          'pk_live_51IcyEcKbddFOe781t3ADDvwFMsMS7cUo51K5GHWoGeV471XkWZWW4Xm8QIp4SawZRG6yHW8UiQe0WeNwFxDau39J00cJeSYWwJ'
        return config
      }
      config =
        'pk_test_51IcyEcKbddFOe781f9njWiCSa7mLVGqR6xc4lV7TfVvWKUJ3yFeLvsi1XK5YDsV2QJbqHxMqoVvb1x9iTZZw5pyH00WgCA9adG'
      return config
    } catch (error) {
      datadogLogs.logger.error('Error getting stripe api config', error)
      throw error
    }
  }
}

export const STRIPE_CONFIG = new StripeEnvironmentConfig(
  <'local' | 'prod' | 'dev' | 'uat'>process.env.ENVIRONMENT,
).getConfig()
