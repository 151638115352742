import { useEffect } from 'react'

export function useHideDialogOnEscPressed(hideDialog: () => void): void {
  const hideDialogOnEscPressed = (event: KeyboardEvent) => {
    if (event.key == 'Escape') {
      hideDialog()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', hideDialogOnEscPressed)
    return () => {
      window.removeEventListener('keydown', hideDialogOnEscPressed)
    }
  })
}
