// Live window access strings
export const LIVE_STREAM_ACCESS_REPLAY_FOR_PREFIX = 'Access replay for '
export const LIVE_STREAM_AFTER_LIVE_STREAM_SUFFIX = ' after live stream'
export const LIVE_STREAM_ACCESS_REPLAY_UNTIL_PREFIX = 'Access replay until '

// Vod window access strings
export const VOD_ACCESS_TO_WATCH_SUFFIX = ' access to watch'
export const VOD_AVAILABLE_TO_WATCH_PREFIX = 'Available to stream until '
export const VOD_DAYS_WHEN_REPLAY_STRINGS_CHANGE = 30

// Common window access strings
export const YOU_HAVE_PREFIX = 'You have '
export const LEFT_TO_WATCH_SUFFIX = ' left to stream'
export const THREE_DAYS = 3
