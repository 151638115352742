import { FlexiPrice } from '@library/_content/_prices/flexi-price'
import { CheckoutTerms } from '@library/_flows/_checkout-video-flow/checkout-terms'
import style from './common.module.scss'
import type { ReactElement } from 'react'
import { useAppSelector as useSel } from '@store/index'
import { selectContentPropByContentId as selContent } from '@store/contents-slice/_selectors'
import ContentSchedule from '@features/_ui/content-schedule'
import ApplyCouponBox from '@components/dialog-provider/dialog/checkout-dialog-content/ticket-box/checkout-details/apply-coupon-box'

export type Props = {
  contentId: string
  channelPathname: string
}

export function FlexiPriceDetails({ contentId, channelPathname }: Readonly<Props>): ReactElement {
  const title = useSel(selContent(contentId)('title')) as string

  return (
    <div className={style.wrapper}>
      <h3>{title}</h3>
      <div className={style.watchText}>
        <ContentSchedule contentId={contentId} />
        <CheckoutTerms contentId={contentId} channelPathname={channelPathname} />
      </div>
      <hr />
      <FlexiPrice contentId={contentId} channelPathname={channelPathname} />
      <ApplyCouponBox productId={contentId} channelPathname={channelPathname} />
    </div>
  )
}
