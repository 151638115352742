import styles from './common.module.css'
import { Intl as IntlPolyfill, Temporal } from '@js-temporal/polyfill'

export interface Props {
  dateTimeString: string
  formatter: IntlPolyfill.DateTimeFormat
}
export default function FormatDateTime({ dateTimeString, formatter }: Props): JSX.Element {
  const dateTime = Temporal.Instant.from(dateTimeString)
  return (
    <time className={styles.time} dateTime={formatter.format(dateTime)}>
      {formatter.format(dateTime)}
    </time>
  )
}
