import type { ReactNode } from 'react'
import { AccessType, AuthButtonBox } from './auth-button-box'
import { CheckoutButtonBox } from './checkout-button-box'
import { selectUserId } from '@store/user-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'

export type ButtonBoxType = 'checkout' | 'auth'

export type Props = {
  children: ReactNode
  channelPathname: string
  accessType: AccessType
}

export function ButtonBox({ children, channelPathname, accessType }: Props): JSX.Element {
  const user = useSel(selectUserId)
  const buttonBoxType = user ? 'checkout' : 'auth'

  const buttonBoxMap: Record<ButtonBoxType, ReactNode> = {
    auth: <AuthButtonBox channelPathname={channelPathname} accessType={accessType} />,
    checkout: <CheckoutButtonBox channelPathname={channelPathname}>{children}</CheckoutButtonBox>,
  }

  return <>{buttonBoxMap[buttonBoxType]}</>
}
