import { toDollar } from '../../_utilities/to-dollar'
import { toDisplayCurrency } from '../../_utilities/to-display-currency'
import style from './common.module.css'
import type { CSSProperties, ReactElement } from 'react'
import { useAppSelector as useSel } from '@root/store'
import {
  selectCodeForCouponValidation,
  selectCouponValidationResponse,
} from '@store/coupons-slice/_selectors'
import { useEffect, useState } from 'react'
import RenderIf from '@utils/render-if'
import { isGreaterThanZero } from '@utils/connascence'
import { isEmptyString } from '@utils/strings'

export type Props = {
  total: number
  currency: string
  className?: string
  style?: CSSProperties
  checkoutTitle: string
}

export function SplitPriceNoFee({
  total,
  currency,
  className = style.defaultClassName,
  style: inlineStyle,
  checkoutTitle,
}: Readonly<Props>): ReactElement {
  // Constants
  const formattedCurrency = toDisplayCurrency(currency)
  const totalDisplay = total === 0 ? 'FREE' : formattedCurrency + toDollar(total)

  // States
  const [totalToPay, setTotalToPay] = useState('')
  const [couponDiscountAmount, setCouponDiscountAmount] = useState('')

  // Selectors
  const couponCodeUsed = useSel(selectCodeForCouponValidation)
  const couponResponse = useSel(selectCouponValidationResponse)

  // Coupon data
  const { isValid, data } = couponResponse || {}

  // Data could be empty if the coupon is invalid so we need to check if it exists before accessing the properties
  const discountedAmountToPayInCents = data?.discountAmountInCents
  const totalAmountToPayInCents = data?.totalAmountToPayInCents

  // Effects
  useEffect(() => {
    if (isValid) {
      setTotalToPay(
        isGreaterThanZero(totalAmountToPayInCents)
          ? formattedCurrency + toDollar(totalAmountToPayInCents)
          : 'FREE',
      )
    }
  }, [totalAmountToPayInCents, discountedAmountToPayInCents, isValid, formattedCurrency])

  useEffect(() => {
    if (isValid) {
      setCouponDiscountAmount(formattedCurrency + toDollar(discountedAmountToPayInCents))
    }
  }, [discountedAmountToPayInCents, isValid, formattedCurrency])

  return (
    <div className={[style.wrapper, className].join(' ')} style={inlineStyle}>
      <div className={style.totalRow}>
        <span>{checkoutTitle}</span>
        <span>{totalDisplay}</span>
      </div>
      <RenderIf isTrue={isValid}>
        <div className={style.itemRow}>
          <span>- {couponCodeUsed}</span>
          <span>{couponDiscountAmount}</span>
        </div>
        <hr />
        <div className={style.totalRow}>
          <span>Total</span>
          <span>{!isEmptyString(totalToPay) ? totalToPay : totalDisplay}</span>
        </div>
      </RenderIf>
    </div>
  )
}
