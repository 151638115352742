import {
  selectContentStateForContentById,
  selectHasAccessToContent,
} from '@store/contents-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import { useEffect } from 'react'

// Scenario 1:
// When Firebase is authenticating, user is not logged in.
// The guide button will be buyNow state, and the user can click it and open the modal.
// After Firebase finish authenticating, this video may has been purchased or booked.
// So use this effect to close the modal under this situation.
// Scenario 2:
// After the user checkout with Stripe, take user back to this dialog.
// After the checkout status change to purchased, this effect close the modal.
export function useHideVideoInfoPageDialog(contentId: string, hideDialog: () => void): void {
  const contentState = useSel(selectContentStateForContentById(contentId)) ?? ''
  const userHasAccessToContent = useSel(selectHasAccessToContent(contentId))

  useEffect(() => {
    if (
      userHasAccessToContent ||
      contentState === 'expired' ||
      contentState === 'soldOut' ||
      contentState === 'accessRestricted' ||
      contentState === 'geoBlocked'
    ) {
      hideDialog()
    }
  }, [hideDialog, contentState])
}
