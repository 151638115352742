import { liveContentScheduleState, LiveContentScheduleState } from 'utils/content-schedule-state'
import { ReactElement, ReactNode } from 'react'
import FormatDateTime from '@features/_ui/format-date-time'
import { dmyFormatter, hmtFormatter } from 'utils/date-time-formatters'
import { formatShortDuration } from 'utils/format-short-duration'
import { getDifferenceInSecondsFromDateStrings } from 'utils/get-difference-in-seconds-from-date-strings'
import RelativeDateString from '@features/_ui/content-schedule/relative-date-string'
import Separator from '@features/_ui/separator'

export interface Props {
  liveStartTime: string
  liveEndTime: string
  liveScheduledExpiry: string | Date
}
export default function LiveSchedule({
  liveStartTime,
  liveEndTime,
  liveScheduledExpiry,
}: Readonly<Props>): ReactElement {
  // Day, month, year date string
  const dmyDate = (
    <div>
      <FormatDateTime dateTimeString={liveStartTime} formatter={dmyFormatter} />
      <Separator type={'dot'} />
    </div>
  )
  // Hour, minute, timezone date string
  const hmtDate = (
    <div>
      <FormatDateTime dateTimeString={liveStartTime} formatter={hmtFormatter} />
      <Separator type={'dot'} />
    </div>
  )
  // Duration string
  const durationFormatted = (
    <RelativeDateString>
      {formatShortDuration(getDifferenceInSecondsFromDateStrings(liveStartTime, liveEndTime))}
    </RelativeDateString>
  )

  const componentMap: Record<LiveContentScheduleState, ReactNode> = {
    ['pre-sale']: (
      <>
        {dmyDate}
        {hmtDate}
        {durationFormatted}
      </>
    ),
    live: (
      <>
        <RelativeDateString>Now</RelativeDateString>
        <Separator type={'dot'} />
        {hmtDate}
        {durationFormatted}
      </>
    ),
    replay: (
      <>
        {dmyDate}
        {hmtDate}
        {durationFormatted}
      </>
    ),
    expired: (
      <>
        {dmyDate}
        {hmtDate}
        {durationFormatted}
      </>
    ),
    premium: <> </>,
    available: <></>,
    ['live-delayed']: <></>,
  }

  return (
    <>
      {
        componentMap[
          liveContentScheduleState({
            startDateTimeString: liveStartTime,
            endDateTimeString: liveEndTime,
            liveScheduledExpiry,
          })
        ]
      }
    </>
  )
}
