import setMarketingOptInByChannelId from '@apis/channels-microservice/set-marketing-opt-in-by-channel-id'
import { Button } from '../../../_buttons'
import {
  selectChannelPropByChannelPathname,
  selectChannelThemeByChannelPathname,
  selectSubscriptionDataForEventTracking,
} from '@store/channel-slice/_selectors'
import { stripePromise } from '@apis/stripe'
import { useAppSelector } from '@root/store'
import { ReactElement, useState } from 'react'
import axiosRequest from '@apis/axios'
import { ChannelSubscriptionEntity } from '@apis/billing-microservice/_entities/_types'
import { TrackingEventsEnum } from '@shared/enums/tracking-events.enum'
import { trackEvent } from '@utils/event-tracking'
import { getReturnUrl } from '@utils/stripe/return-url'
import { useCheckoutContext } from '@providers/checkout-provider/_contexts'
import { useSelector } from 'react-redux'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { selectCodeForCouponValidation } from '@store/coupons-slice/_selectors'

export type Props = {
  channelPathname: string
  subscriptionId: string
  hideDialog: () => void
}

export function SubscribeButton({
  channelPathname,
  subscriptionId,
  hideDialog,
}: Readonly<Props>): ReactElement {
  // Context
  const { isJoinMailingList } = useCheckoutContext()

  // Local States
  const [isLoading, setIsLoading] = useState(false)

  // Selectors
  const channelId = useAppSelector(selectChannelPropByChannelPathname(channelPathname)('channelId'))
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))
  const eventTrackingData = useAppSelector(
    selectSubscriptionDataForEventTracking({ subscriptionId, channelPathname }),
  ) // Get the event tracking data for the event tracking trigger
  const promotionCode = useAppSelector(selectCodeForCouponValidation)

  // Flags
  const isCouponsForViewersActive = useSelector(
    selectAppFeatureFlagStatusByName('flagCouponsForViewers'),
  )

  const subscribe = async () => {
    setIsLoading(true)
    // Trigger tracking event
    trackEvent(TrackingEventsEnum.INITIATE_CHECKOUT, eventTrackingData)
    // Set marketing opt in by channel
    setMarketingOptInByChannelId(channelId, isJoinMailingList)

    const { sessionId } = (await axiosRequest(
      'billing',
      {
        method: 'POST',
        url: 'subscription-checkout',
        data: {
          channelId,
          subscriptionId,
          returnUrl: getReturnUrl(window.location.href),
          promotionCode,
        },
      },
      true,
      // If isCouponsForViewersActive is active hit the new v2 of this endpoint
      // which includes the correct promotion code handling for the subscription.
      isCouponsForViewersActive ? '2' : '1',
    )) as ChannelSubscriptionEntity

    // Hide the dialog and redirect checkout.
    hideDialog()
    const stripe = await stripePromise
    await stripe?.redirectToCheckout({ sessionId })

    setIsLoading(false)
  }

  return (
    <Button
      isLoading={isLoading}
      onClick={subscribe}
      isFullWidth={true}
      channelTheme={channelTheme}
    >
      Checkout
    </Button>
  )
}
