import style from './common.module.scss'
import type { CheckoutDialogParams } from '../_types'
import { ButtonBox } from '../button-box'
import { ChannelBox } from '../channel-box'
import { CheckoutButton } from '@library/_flows/_checkout-video-flow/checkout-button'
import { TicketBox } from './ticket-box'
import { useHideVideoInfoPageDialog } from '../_hooks/use-hide-video-info-page-dialog'
import { ReactElement } from 'react'
import { useGtmPageUser } from '@apis/google-analytics/_hooks/use-gtm-page-user'
import { useAppSelector } from '@root/store'
import { selectContentPropByContentId } from '@store/contents-slice/_selectors'

export type Props = {
  dialogParams: CheckoutDialogParams
  hideDialog: () => void
}

export function CheckoutDialogContent({
  dialogParams: { contentId, channelPathname },
  hideDialog,
}: Readonly<Props>): ReactElement {
  const liveTitle = useAppSelector(selectContentPropByContentId(contentId)('title')) ?? ''
  const gtmPage = `/${channelPathname}/checkout=${liveTitle}`

  useGtmPageUser(gtmPage)
  useHideVideoInfoPageDialog(contentId, hideDialog)

  return (
    <div className={style.wrapper}>
      <h2>Confirm order</h2>
      <TicketBox contentId={contentId} channelPathname={channelPathname} />
      <ButtonBox channelPathname={channelPathname} accessType={'content'}>
        <CheckoutButton
          contentId={contentId}
          channelPathname={channelPathname}
          hideDialog={hideDialog}
        />
      </ButtonBox>
    </div>
  )
}
