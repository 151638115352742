import PurchaseAndFreeDetails from './purchase-and-free-details'
import type { ReactNode, ReactElement } from 'react'
import { FlexiPriceDetails } from './flexi-price-details'
import { selectContentPricesByContentId } from '@store/contents-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import { isEqualToOne, isEqualToZero, isGreaterThanOne } from '@root/utils/connascence'

export type Variant = 'default'

export type Props = {
  contentId: string
  channelPathname: string
}

export function CheckoutDetails({ contentId, channelPathname }: Readonly<Props>): ReactElement {
  const contentPrices = useSel(selectContentPricesByContentId(contentId)) ?? []

  const renderComponent = (): ReactNode => {
    const contentPricesCount = contentPrices.length
    if (isEqualToZero(contentPricesCount)) {
      return <></>
    } else if (isEqualToOne(contentPricesCount)) {
      return <PurchaseAndFreeDetails contentId={contentId} channelPathname={channelPathname} />
    } else if (isGreaterThanOne(contentPricesCount)) {
      return <FlexiPriceDetails contentId={contentId} channelPathname={channelPathname} />
    } else {
      return <></>
    }
  }

  return <>{renderComponent()}</>
}
