import { useEffect } from 'react'

/**
 * This hook depend on the following global style:
 * <pre>
 * html {
 *   height: 100%;
 *   overflow-x: hidden;
 *   overflow-y: auto;
 * }
 * body {
 *   overflow-x: hidden;
 * }
 * </pre>
 */
export function useHideBodyOverflow(): void {
  const bodyWidthPx = parseInt(window.getComputedStyle(document.body).width.slice(0, -2))
  const windowWidthPx = window.innerWidth
  const scrollBarWidthPx = windowWidthPx - bodyWidthPx

  /**
   * Make the body non-static,
   * so the absolute positioned header will stay in the body,
   * and respect the body margin when the dialog pop up.
   * Currently, only support absolute positioned header
   * TODO create a global css variable --htmlScrollbarPlaceHolderWidth to support fix positioned header
   */
  document.body.style.position = 'relative'
  document.documentElement.style.overflow = 'hidden'
  document.body.style.marginRight = scrollBarWidthPx.toString() + 'px'

  useEffect(() => {
    return () => {
      document.body.style.marginRight = ''
      document.documentElement.style.overflow = ''
      document.body.style.position = ''
    }
  }, [])
}
