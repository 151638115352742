// Select Status
import { RootState } from '@root/store'

export const selectCouponValidationStatus = (state: RootState) => state.coupons.status

// Select error messages for coupon validation
export const selectCouponValidationErrorMessages = (state: RootState) =>
  state.coupons?.errorMessages

// Select coupon response
export const selectCouponValidationResponse = (state: RootState) => state.coupons?.response

// Select coupon code for validation
export const selectCodeForCouponValidation = (state: RootState) => state.coupons?.code
