import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import {
  selectContentPricesByContentId,
  selectContentPropByContentId,
  selectContentStateForContentById,
} from '@store/contents-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import { useGetWindowAccessStringFromContent } from '@features/_ui/prices/hooks/use-get-window-access-string'
import { isGreaterThanOne } from '@root/utils/connascence'

interface Props {
  contentId: string
  channelPathname: string
}

export function useCreatorTerms({ contentId, channelPathname }: Props): string {
  const state = useSel(selectContentStateForContentById(contentId))
  const displayName = useSel(selectChannelPropByChannelPathname(channelPathname)('displayName'))
  const additionalInformation =
    useSel(selectContentPropByContentId(contentId)('additionalInformation')) ?? ''

  const contentPrices = useSel(selectContentPricesByContentId(contentId))

  const windowAccessStringFromContent = useGetWindowAccessStringFromContent(contentId)

  const renderContentPriceTerms = (): string => {
    if (state === 'accessRestricted' || state === 'ticketedOnly') {
      return `<ul><li>Access by invitation only. Please contact ${displayName} for access.</li></ul>`
    }
    if (isGreaterThanOne(contentPrices.length)) {
      // Window access string for content with more than one price needs to go in the terms
      return windowAccessStringFromContent
        ? `<ul><li>${windowAccessStringFromContent}</li></ul>`
        : ''
    }
    return ''
  }

  return additionalInformation + renderContentPriceTerms()
}
