import styles from './common.module.css'
import { useAppSelector as useSel } from '@store/index'
import {
  selectBundlePropByContentId,
  selectContentPropByContentId,
  selectExternalLinkPropByContentId,
  selectLivePropByContentId,
  selectVodPropByContentId,
} from '@store/contents-slice/_selectors'
import { Dispatch, ReactElement, ReactNode, SetStateAction, useEffect, useRef } from 'react'
import { ContentType } from '@apis/contents-microservice/_entities/_types'
import LiveSchedule from '@features/_ui/content-schedule/live-schedule'
import VodAudioSchedule from '@features/_ui/content-schedule/vod-audio-schedule'
import BundleSchedule from '@features/_ui/content-schedule/bundle-schedule'
import ExternalLinkSchedule from '@features/_ui/content-schedule/external-link-schedule'
import { ContentBadge } from '@features/_ui/content-badge'
import RenderIf from '@utils/render-if'
import { ExternalLinkTypeEnum } from '@shared/enums/layout/external-link-type.enum'
import { datadogLogs } from '@datadog/browser-logs'
import useGetLivestreamTimes from '@hooks/use-get-livestream-times'

export interface Props {
  contentId: string
  className?: string
  callback?: Dispatch<SetStateAction<boolean>>
}

export default function ContentSchedule({
  contentId,
  className,
  callback,
}: Readonly<Props>): ReactElement {
  const scheduleRef = useRef<HTMLDivElement>()
  const contentType = useSel(selectContentPropByContentId(contentId)('contentType'))

  // lives
  const liveStartTime = useSel(selectLivePropByContentId(contentId)('scheduledStartTime'))
  const liveEndTime = useSel(selectLivePropByContentId(contentId)('scheduledEndTime'))

  // Hooks
  const { liveScheduledExpiry } = useGetLivestreamTimes({
    contentId,
  })

  // vod + bundle
  const vodOrBundleStartTime = useSel(
    selectContentPropByContentId(contentId)('scheduledReleaseDate'),
  )
  const vodOrBundleEndTime = useSel(selectContentPropByContentId(contentId)('scheduledExpiryDate'))
  const vodDuration = useSel(selectVodPropByContentId(contentId)('length'))
  const bundleDisplayStartDate = useSel(selectBundlePropByContentId(contentId)('displayStartDate'))
  const bundleDisplayEndDate = useSel(selectBundlePropByContentId(contentId)('displayEndDate'))

  // external link
  const externalStartTime = useSel(
    selectExternalLinkPropByContentId(contentId)('scheduledStartTime'),
  )
  const externalEndTime = useSel(selectExternalLinkPropByContentId(contentId)('scheduledEndTime'))
  const externalLinkType = useSel(selectExternalLinkPropByContentId(contentId)('externalLinkType'))

  const componentMap: Record<ContentType, ReactNode> = {
    live: (
      <>
        <ContentBadge contentId={contentId} />
        <LiveSchedule
          liveStartTime={liveStartTime}
          liveEndTime={liveEndTime}
          liveScheduledExpiry={liveScheduledExpiry}
        />
      </>
    ),
    vod: (
      <>
        <ContentBadge contentId={contentId} />
        <VodAudioSchedule
          startTime={vodOrBundleStartTime}
          endTime={vodOrBundleEndTime}
          duration={vodDuration}
        />
      </>
    ),
    audio: (
      <>
        <ContentBadge contentId={contentId} />
        <VodAudioSchedule
          startTime={vodOrBundleStartTime}
          endTime={vodOrBundleEndTime}
          duration={vodDuration}
        />
      </>
    ),
    bundle: (
      <>
        <ContentBadge contentId={contentId} />
        <BundleSchedule
          bundleStartTime={vodOrBundleStartTime}
          bundleEndTime={vodOrBundleEndTime}
          bundleDisplayStartDate={bundleDisplayStartDate}
          bundleDisplayEndDate={bundleDisplayEndDate}
        />
      </>
    ),
    ['external-link']: (
      // Only render badge and schedule if external link type is one-off virtual event meeting
      // Otherwise the external link is a one on one booking and no badge needed
      <RenderIf isTrue={externalLinkType === ExternalLinkTypeEnum.ONE_OFF_VIRTUAL_EVENT_MEETING}>
        <ContentBadge contentId={contentId} />
        <ExternalLinkSchedule
          externalLinkStartTime={externalStartTime}
          externalLinkEndTime={externalEndTime}
        />
      </RenderIf>
    ),
  }

  const isScheduleEmpty = () => {
    try {
      return !scheduleRef.current || !scheduleRef.current.innerHTML.trim()
    } catch (error) {
      datadogLogs.logger.warn('THEME: Error validating empty schedule box', error)
      return false
    }
  }

  /**
   * Small code to determinate if the schedule box has content.
   */
  useEffect(() => {
    if (callback) {
      callback(!isScheduleEmpty())
    }
  }, [callback])

  return (
    <div ref={scheduleRef} className={[styles.container, className].join(' ')}>
      {componentMap[contentType]}
    </div>
  )
}
