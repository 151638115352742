import style from './common.module.scss'
import PassTerms from '@features/_ui/pass-terms/index.'
import { PassSplitPrice } from '@library/_flows/_channel_pass-flow/pass-split-price'
import { useAppSelector } from '@root/store'
import { selectChannelPassById } from '@store/channel-slice/_selectors'
import ApplyCouponBox from '@components/dialog-provider/dialog/checkout-dialog-content/ticket-box/checkout-details/apply-coupon-box'
import { ProductTypes } from '@store/coupons-slice/interfaces'
import { ReactElement } from 'react'

export type Props = {
  channelPathname: string
  passId: string
}

export function PassDetails({ channelPathname, passId }: Readonly<Props>): ReactElement {
  const pass = useAppSelector(selectChannelPassById(channelPathname, passId))

  return (
    <div className={style.wrapper}>
      <h3 className={style.h3}>{pass?.name}</h3>
      <PassTerms channelPathname={channelPathname} passId={passId} variant={'dialog'} />
      <hr />
      <PassSplitPrice channelPathname={channelPathname} passId={passId} />
      <ApplyCouponBox
        productId={passId}
        channelPathname={channelPathname}
        productType={ProductTypes.PASS}
      />
    </div>
  )
}
