import { useAppSelector as useSel } from '@root/store'
import {
  selectAudioPropByContentId,
  selectBundlePropByContentId,
  selectContentPropByContentId,
  selectContentStateExpiryByContentId,
  selectVodPropByContentId,
} from '@store/contents-slice/_selectors'
import { contentScheduleState, liveContentScheduleState } from '@root/utils/content-schedule-state'
import { getLiveWindowAccessString } from '@root/utils/window-access/live-window-access'
import { getVodBundleAudioWindowAccessString } from '@root/utils/window-access/vod-audio-bundle-window-access'
import useGetLivestreamTimes from '@hooks/use-get-livestream-times'

export function useGetWindowAccessStringFromContent(
  contentId: string,
  availabilityDuration?: number,
): string {
  const contentType = useSel(selectContentPropByContentId(contentId)('contentType'))
  const contentState = useSel(selectContentPropByContentId(contentId)('state'))

  // Get expiry time from state and convert to iso string
  const expiryTimeFromState = useSel(selectContentStateExpiryByContentId(contentId))

  // Hooks
  const { liveStartTimeToUse, liveEndTime, liveScheduledExpiry, liveReplayAvailability } =
    useGetLivestreamTimes({
      contentId,
    })

  const liveState = liveContentScheduleState({
    startDateTimeString: liveStartTimeToUse,
    endDateTimeString: liveEndTime,
    liveScheduledExpiry,
  })

  // Vod + bundle + audio window data
  const vodBundleAudioStartDate = useSel(
    selectContentPropByContentId(contentId)('scheduledReleaseDate'),
  )
  // Get expiry date from scheduledExpiryDate
  const vodBundleAudioScheduledExpiry = useSel(
    selectContentPropByContentId(contentId)('scheduledExpiryDate'),
  )
  // If expiry time from button state is not available check scheduledExpiryDate of the content
  const vodBundleAudioEndDate = expiryTimeFromState ?? vodBundleAudioScheduledExpiry

  const vodAvailability = useSel(selectVodPropByContentId(contentId)('availabilityDuration'))
  const bundleAvailability = useSel(selectBundlePropByContentId(contentId)('availabilityDuration'))
  const audioAvailability = useSel(selectAudioPropByContentId(contentId)('availabilityDuration'))
  const vodBundleAudioState = contentScheduleState(vodBundleAudioStartDate, vodBundleAudioEndDate)

  if (contentType) {
    switch (contentType) {
      case 'live':
        return getLiveWindowAccessString({
          contentState,
          liveState,
          liveEndTime,
          liveReplay: liveReplayAvailability,
        })
      case 'vod':
        return getVodBundleAudioWindowAccessString({
          contentState,
          state: vodBundleAudioState,
          expiryDate: vodBundleAudioEndDate,
          availabilityDuration: availabilityDuration ?? vodAvailability,
        })
      case 'audio':
        return getVodBundleAudioWindowAccessString({
          contentState,
          state: vodBundleAudioState,
          expiryDate: vodBundleAudioEndDate,
          availabilityDuration: availabilityDuration ?? audioAvailability,
        })
      case 'bundle':
        return getVodBundleAudioWindowAccessString({
          contentState,
          state: vodBundleAudioState,
          expiryDate: vodBundleAudioEndDate,
          availabilityDuration: availabilityDuration ?? bundleAvailability,
        })
      case 'external-link':
        return undefined
    }
  }
}
