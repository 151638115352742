import { RootState } from '@root/store'
import { PassStatusInterface } from '@shared/interfaces/creators/channel-pass/pass-status.interface'

export const selectPassStatusByPassId =
  (passId: string) =>
  ({ passes }: RootState): PassStatusInterface => {
    return passId ? passes.byPassStatusById?.[passId] : undefined
  }

export const selectHasActivePassByChannelPathname =
  (channelPathname: string) =>
  ({ passes }: RootState): boolean => {
    return channelPathname ? passes.byChannelPathname?.[channelPathname]?.isActive : false
  }
