import {
  selectIsSubscribedByChannelPathname,
  selectIsSubscribedBySubscriptionId,
  selectSubscriberPropByChannelPathname as sel,
} from '@store/subscriber-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import { useEffect } from 'react'

interface Props {
  subscriptionId: string
  hideDialog: () => void
  channelPathname: string
}
// Scenario 1:
// When Firebase is authenticating, user is not logged in.
// The guide button will be subscribe state, and the user can click it and open the modal.
// After Firebase finish authenticating, this channel may has been subscribed.
// So use this effect to close the modal under this situation.
// Scenario 2:
// After the user checkout with Stripe, take user back to this dialog.
// After the subscription status change to subscribed, this effect close the modal.
// // Scenario 3:
// The user has an old subscription and still subscribed to this channel. We need to block
// the user from subscribing again.
export function useHideChannelPageDialog({
  hideDialog,
  subscriptionId,
  channelPathname,
}: Props): void {
  const isSubscribed = useSel(selectIsSubscribedBySubscriptionId(subscriptionId))
  const isSubscribedByChannelPathname = useSel(selectIsSubscribedByChannelPathname(channelPathname))

  useEffect(() => {
    if (isSubscribed || isSubscribedByChannelPathname) {
      hideDialog()
    }
  }, [hideDialog, isSubscribed, isSubscribedByChannelPathname])
}
