import style from './common.module.scss'
import { PassDetails } from './pass-details'
import { useAppSelector } from '@root/store'
import { selectChannelPassById } from '@store/channel-slice/_selectors'
import { DIALOG_WIDTH_THUMB } from '@root/constants'
import { Thumbnail } from '@library/_content'
import { ReactElement } from 'react'

export type Props = {
  channelPathname: string
  passId: string
}

export function TicketBox({ channelPathname, passId }: Readonly<Props>): ReactElement {
  const pass = useAppSelector(selectChannelPassById(channelPathname, passId))

  return (
    <div className={style.content}>
      <Thumbnail
        className={style.thumbnail}
        thumbProps={{
          thumbnailSrc: pass?.thumbnail,
          title: pass?.name,
          thumbnailAlt: pass?.name,
        }}
        width={DIALOG_WIDTH_THUMB}
        contentId={''}
      />
      <div className={style.details}>
        <PassDetails channelPathname={channelPathname} passId={passId} />
      </div>
    </div>
  )
}
