import style from './styles.module.scss'
import type { SubscribeDialogParams } from '../_types'
import { ButtonBox } from '../button-box'
import { SubscribeButton } from '@library/_flows/_subscribe-channel-flow/subscribe-button'
import { TicketBox } from './ticket-box'
import { useHideChannelPageDialog } from '../_hooks/use-hide-channel-page-dialog'
import { ReactElement } from 'react'
import { useGtmPageUser } from '@apis/google-analytics/_hooks/use-gtm-page-user'

export type Props = {
  dialogParams: SubscribeDialogParams
  hideDialog: () => void
}

export function SubscribeDialogContent({
  dialogParams: { channelPathname, subscriptionId },
  hideDialog,
}: Readonly<Props>): ReactElement {
  const gtmPage = `/${channelPathname}/subscribe-checkout`
  useGtmPageUser(gtmPage)
  useHideChannelPageDialog({ subscriptionId, hideDialog, channelPathname })

  return (
    <div className={style.wrapper}>
      <h2>Confirm order</h2>
      <TicketBox channelPathname={channelPathname} subscriptionId={subscriptionId} />
      <ButtonBox channelPathname={channelPathname} accessType={'channel'}>
        <SubscribeButton
          channelPathname={channelPathname}
          subscriptionId={subscriptionId}
          hideDialog={hideDialog}
        />
      </ButtonBox>
    </div>
  )
}
