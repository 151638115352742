import { FetchStatus } from '@store/_types'

export interface CouponsState {
  response: CouponResponse
  errorMessages: string
  status: FetchStatus
  code: string
}

export interface CouponResponse {
  data: CouponData
  isValid?: boolean
}

export interface CouponData {
  originalAmountInCents: number
  discountAmountInCents: number
  discountedAmountToPayInCents: number
  serviceFeeInCents: number
  totalAmountToPayInCents: number
}

export interface ValidateCouponPayload {
  promotionCode: string
  organisationId: string
  productId: string
  channelId: string
  productType: ProductTypes
  price: number
  currency: string
}

export enum ProductTypes {
  CONTENT = 'content',
  SUBSCRIPTION = 'subscription',
  PASS = 'pass',
}
