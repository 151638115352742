import type { SubscriptionState } from './_types'
import type { FetchStatus } from '../_types'
import type { RootState } from '..'

export const selectSubscriberPropByChannelPathname =
  (channelPathname: string) =>
  <PropKey extends keyof SubscriptionState>(propKey: PropKey) =>
  ({ subscribers }: RootState): SubscriptionState[PropKey] | undefined => {
    const bill = subscribers?.byChannelPathname?.[channelPathname]

    // If property value is null, return undefined
    return bill?.[propKey] ?? undefined
  }

export const selectIsSubscribedBySubscriptionId =
  (subscriptionId: string) =>
  ({ subscribers }: RootState): boolean => {
    return subscribers?.bySubscriptionId?.[subscriptionId]?.isSubscribed ?? undefined
  }

export const selectIsSubscribedByChannelPathname =
  (channelPathname: string) =>
  ({ subscribers }: RootState): boolean => {
    return subscribers?.byChannelPathname?.[channelPathname]?.isSubscribed ?? undefined
  }

export const selectSubscriberStatus = ({ subscribers }: RootState): FetchStatus => {
  return subscribers.fetchStatus
}

export const selectSubscriberErrorMessages = ({ subscribers }: RootState): string => {
  return subscribers.errorMessages
}
