import extractVisibleCharacters from './extractVisibleCharacters'
import { useCreatorTerms } from './use-creator-terms'
import useUserCanAccess from '@library/_hooks/use-user-can-geo-access'
import { useEffect, useState } from 'react'
import { GEO_NOT_AVAILABLE_IN_YOUR_COUNTRY } from '@root/constants'
import dynamic from 'next/dynamic'
const RichTextRenderer = dynamic(() => import('@library/_texts/rich-text-renderer'), { ssr: false })

export type Props = {
  contentId: string
  channelPathname: string
  className?: string
}

export function CheckoutTerms({ contentId, className, channelPathname }: Props): JSX.Element {
  const [rendered, setRendered] = useState<boolean>(false)

  // with bullet points
  const creatorTerms = useCreatorTerms({ contentId, channelPathname })

  // Geo blocking hook
  const hasGeoAccess = useUserCanAccess({ contentId })
  const geoMessage = `<ul><li>${GEO_NOT_AVAILABLE_IN_YOUR_COUNTRY}</li></ul>`

  const terms = creatorTerms + (!hasGeoAccess ? geoMessage : '')

  // used to validate if the component were rendered
  useEffect(() => {
    setRendered(true)
  }, [])

  return (
    <>
      {rendered && extractVisibleCharacters(terms) && (
        <RichTextRenderer className={className}>{terms}</RichTextRenderer>
      )}
    </>
  )
}
