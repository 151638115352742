interface Props {
  fill?: string
}
export default function TickIcon({ fill }: Props): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14261_47174)">
        <path
          d="M7.65949 17.4724C7.46765 17.6643 7.20866 17.7698 6.93527 17.7698C6.66189 17.7698 6.4029 17.6643 6.21105 17.4724L0.450861 11.7026C-0.148659 11.1031 -0.148659 10.1343 0.450861 9.53477L1.17508 8.81055C1.7746 8.21103 2.74343 8.21103 3.34295 8.81055L6.94487 12.4125L16.6619 2.69065C17.2614 2.09113 18.2302 2.09113 18.8298 2.69065L19.554 3.41487C20.1535 4.01439 20.1535 4.98321 19.554 5.58273L7.65949 17.4724Z"
          fill={fill ?? 'url(#paint0_linear_14261_47174)'}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_14261_47174"
          x1="-0.919854"
          y1="4.91095"
          x2="16.3944"
          y2="11.8808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2139BE" />
          <stop offset="0.0410847" stopColor="#2A4DC6" />
          <stop offset="0.1438" stopColor="#3E7AD8" />
          <stop offset="0.2425" stopColor="#4E9DE6" />
          <stop offset="0.3351" stopColor="#5AB6F0" />
          <stop offset="0.419" stopColor="#61C6F6" />
          <stop offset="0.4866" stopColor="#63CBF8" />
          <stop offset="0.5122" stopColor="#62CDF4" />
          <stop offset="0.8378" stopColor="#57E1C4" />
          <stop offset="1" stopColor="#53E9B2" />
        </linearGradient>
        <clipPath id="clip0_14261_47174">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
