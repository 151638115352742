import { contentScheduleState, ContentScheduleState } from 'utils/content-schedule-state'
import { ReactNode } from 'react'
import FormatDateTime from '@features/_ui/format-date-time'
import { dmyFormatter, hmtFormatter } from 'utils/date-time-formatters'
import { formatShortDuration } from 'utils/format-short-duration'
import { getDifferenceInSecondsFromDateStrings } from 'utils/get-difference-in-seconds-from-date-strings'
import RelativeDateString from '@features/_ui/content-schedule/relative-date-string'
import Separator from '@features/_ui/separator'

export interface Props {
  externalLinkStartTime: string
  externalLinkEndTime: string
}
export default function ExternalLinkSchedule({
  externalLinkStartTime,
  externalLinkEndTime,
}: Props): JSX.Element {
  // Day, month, year date string
  const dmyDate = (
    <div>
      <FormatDateTime dateTimeString={externalLinkStartTime} formatter={dmyFormatter} />
      <Separator type={'dot'} />
    </div>
  )
  // Hour, minute, timezone date string
  const hmtDate = (
    <div>
      <FormatDateTime dateTimeString={externalLinkStartTime} formatter={hmtFormatter} />
      <Separator type={'dot'} />
    </div>
  )
  // Duration string
  const durationFormatted = (
    <RelativeDateString>
      {formatShortDuration(
        getDifferenceInSecondsFromDateStrings(externalLinkStartTime, externalLinkEndTime),
      )}
    </RelativeDateString>
  )

  const componentMap: Record<ContentScheduleState, ReactNode> = {
    ['pre-sale']: (
      <>
        {dmyDate}
        {hmtDate}
        {durationFormatted}
      </>
    ),
    live: (
      <>
        <RelativeDateString>Now</RelativeDateString>
        <Separator type={'dot'} />
        {hmtDate}
        {durationFormatted}
      </>
    ),
    expired: (
      <>
        {dmyDate}
        {hmtDate}
        {durationFormatted}
      </>
    ),
    premium: <> </>,
    available: <></>,
  }

  return <>{componentMap[contentScheduleState(externalLinkStartTime, externalLinkEndTime)]}</>
}
