import style from './common.module.scss'
import { SubscribeDetails } from './subscribe-details'
import { DIALOG_WIDTH_THUMB } from '@root/constants'
import { Thumbnail } from '@library/_content'
import { useAppSelector } from '@root/store'
import { selectSubscriptionDataBySubscriptionId } from '@store/channel-slice/_selectors'
import { ReactElement } from 'react'

export type Props = {
  channelPathname: string
  subscriptionId: string
}

export function TicketBox({ channelPathname, subscriptionId }: Readonly<Props>): ReactElement {
  const subscriptionData = useAppSelector(
    selectSubscriptionDataBySubscriptionId(channelPathname, subscriptionId),
  )

  return (
    <div className={style.content}>
      <Thumbnail
        className={style.thumbnail}
        thumbProps={{
          thumbnailSrc: subscriptionData?.imageUrl,
          title: subscriptionData?.title,
          thumbnailAlt: subscriptionData?.title,
        }}
        width={DIALOG_WIDTH_THUMB}
        contentId={''}
      />
      <div className={style.details}>
        <SubscribeDetails channelPathname={channelPathname} subscriptionId={subscriptionId} />
      </div>
    </div>
  )
}
