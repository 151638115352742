import {
  selectContentGeoBlockCountries as geoCountries,
  selectContentGeoBlockAllowed as geoAllowed,
} from '@store/contents-slice/_selectors'
import { selectUserCountryCode } from '@store/geo-slice/_selectors'
import { useAppSelector as useSel } from '@root/store'

interface Props {
  contentId: string
}

export default function useUserCanAccess({ contentId }: Readonly<Props>): boolean {
  const contentGeoCountries = useSel(geoCountries(contentId))
  const contentGeoAllowed = useSel(geoAllowed(contentId))
  const userGeoCountry = useSel(selectUserCountryCode)

  /**
   * When contentGeoAllowed is false, we need to validate if the user country is in the countries list.
   * If it is, we need to return false because the user country is blocked for the content
   *
   * When contentGeoAllowed is true, and the user country exist into the list. we need to return true
   * because the user country is into the countries allowed
   */
  if (contentGeoCountries && contentGeoCountries.length > 0) {
    const inCountry = contentGeoCountries.find((country) => country === userGeoCountry)
    return (typeof inCountry === 'undefined') !== contentGeoAllowed
  } else {
    return true
  }
}
