import { Intl as IntlPolyfill } from '@js-temporal/polyfill'

function getLocale(): string {
  if (typeof window !== 'undefined') {
    return navigator.language
  }
}

export const dmyFormatter = new IntlPolyfill.DateTimeFormat(getLocale(), {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})

export const dFormatter = new IntlPolyfill.DateTimeFormat(getLocale(), {
  day: 'numeric',
})

export const hmFormatter = new IntlPolyfill.DateTimeFormat(getLocale(), {
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
})

export const hmtFormatter = new IntlPolyfill.DateTimeFormat(getLocale(), {
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
  timeZoneName: 'short',
})
