import { useAppSelector as useSel } from '@root/store'
import { useEffect } from 'react'
import { selectPassStatusByPassId } from '@store/pass-slice/_selectors'

export function useHidePassPageDialog(
  passId: string,
  channelPathname: string,
  hideDialog: () => void,
): void {
  const hasPurchased = useSel(selectPassStatusByPassId(passId))?.isActive ?? false

  useEffect(() => {
    if (hasPurchased) {
      hideDialog()
    }
  }, [hideDialog, hasPurchased])
}
