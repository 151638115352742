import style from '@features/_ui/separator/common.module.css'
import { ReactNode } from 'react'

export type SeparatorType = 'dot' | 'dash'
export interface Props {
  type: SeparatorType
}

export default function Separator({ type }: Props): JSX.Element {
  const componentMap: Record<SeparatorType, ReactNode> = {
    dot: <span className={style.wrapper}>&nbsp;&bull;&nbsp;</span>,
    dash: <span className={style.wrapper}>&nbsp;&ndash;&nbsp;</span>,
  }
  return <>{componentMap[type]}</>
}
