import modalStyles from './index.module.css'
import { GEO_MODAL_TITLE } from '@root/constants'
import { selectUserCountryName } from '@store/geo-slice/_selectors'
import { useAppSelector } from '@root/store'
import { Button } from '@library/_buttons'
import { useContext } from 'react'
import { DispatchNewParamsContext } from '@components/dialog-provider'
import type { GeoBlockAlert } from '@components/dialog-provider/dialog/_types'
import { selectContentStateForContentById } from '@store/contents-slice/_selectors'
import { selectChannelThemeByChannelPathname } from '@store/channel-slice/_selectors'

interface Props {
  hideDialog: () => void
  dialogParams: GeoBlockAlert
}

export default function GeoBlockingModal({ hideDialog, dialogParams }: Props): JSX.Element {
  const dispatch = useContext(DispatchNewParamsContext)
  const { channelPathname, contentId } = dialogParams
  const userGeoCountry = useAppSelector(selectUserCountryName)
  const message = `We see you’re visiting from ${userGeoCountry}. This stream is not available in your region.`
  const state = useAppSelector(selectContentStateForContentById(contentId)) ?? ''
  const purchaseState = state === 'watchNow' || state === 'joinNow'
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))

  function checkOut(): void {
    dispatch({ dialogType: 'checkout', channelPathname, contentId, url: window.location.href })
  }

  return (
    <div className={modalStyles.wrapper}>
      <div className={modalStyles.wrapperContent}>
        <h2>{GEO_MODAL_TITLE}</h2>
        <p>{message}</p>
        {!purchaseState && (
          <div className={modalStyles.buttons}>
            <Button
              className={[modalStyles.secondaryBtn, modalStyles.btn].join(' ')}
              onClick={hideDialog}
              variant={'secondary'}
            >
              Cancel
            </Button>
            <Button
              className={modalStyles.btn}
              onClick={checkOut}
              variant={'primary'}
              channelTheme={channelTheme}
            >
              Continue
            </Button>
          </div>
        )}
        {purchaseState && (
          <div className={modalStyles.closeButton}>
            <Button
              className={[modalStyles.secondaryBtn, modalStyles.btn].join(' ')}
              onClick={hideDialog}
              variant={'primary'}
              channelTheme={channelTheme}
            >
              Close
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
