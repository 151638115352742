import style from './common.module.css'
import type { ReactNode } from 'react'
import { useHideBodyOverflow } from './_hooks/use-hide-body-overflow'
import { useHideDialogOnEscPressed } from './_hooks/use-hide-dialog-on-esc-pressed'
import { CloseButton } from './close-button'
import { DialogContentBox } from './dialog-content-box'

export type Props = {
  children: ReactNode
  hideDialog: () => void

  className?: string
}

export function Backdrop({
  children,
  hideDialog,
  className = style.defaultClassName,
}: Props): JSX.Element {
  useHideBodyOverflow()
  useHideDialogOnEscPressed(hideDialog)

  return (
    <div
      className={style.backdrop}
      onClick={hideDialog}
      onKeyDown={hideDialog}
      role="button"
      tabIndex={-1}
    >
      <DialogContentBox className={className}>
        <CloseButton className={style.closeButton} onClick={hideDialog} />
        {children}
      </DialogContentBox>
    </div>
  )
}
