import { useAppDispatch, useAppSelector as useSel } from '@root/store'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import { useEffect } from 'react'
import { getChannelPassStatusThunk } from '@store/pass-slice/_thunks'

export function usePassStatus(channelPathname: string, passId: string): void {
  const channelId = useSel(selectChannelPropByChannelPathname(channelPathname)('channelId'))
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (channelId && passId) {
      dispatch(getChannelPassStatusThunk({ passIds: [passId], channelId, channelPathname }))
    }
  }, [channelPathname, dispatch, channelId, passId])
}
