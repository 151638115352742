import style from './common.module.scss'
import {
  selectContentPricesByContentId,
  selectContentPropByContentId as selContent,
} from '@store/contents-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import { CheckoutTerms } from '@library/_flows/_checkout-video-flow/checkout-terms'
import CheckoutSplitPrice from '@library/_flows/_checkout-video-flow/checkout-split-price'
import ContentSchedule from '@features/_ui/content-schedule'
import { ReactElement, useEffect } from 'react'
import ApplyCouponBox from '@components/dialog-provider/dialog/checkout-dialog-content/ticket-box/checkout-details/apply-coupon-box'
import { useCheckoutContext } from '@providers/checkout-provider/_contexts'

export type Props = {
  contentId: string
  channelPathname: string
}

export default function PurchaseAndFreeDetails({
  contentId,
  channelPathname,
}: Readonly<Props>): ReactElement {
  const title = useSel(selContent(contentId)('title')) as string
  const contentPrices = useSel(selectContentPricesByContentId(contentId))
  const contentPricesCount = contentPrices?.length

  // Context
  const { setFlexiPrice } = useCheckoutContext()

  useEffect(() => {
    if (contentPricesCount) {
      setFlexiPrice(contentPrices[0].price)
    }
  }, [])

  return (
    <div className={style.wrapper}>
      <h3>{title}</h3>
      <div className={style.watchText}>
        <ContentSchedule contentId={contentId} />
        <CheckoutTerms contentId={contentId} channelPathname={channelPathname} />
      </div>
      <hr />
      <CheckoutSplitPrice contentId={contentId} channelPathname={channelPathname} />
      <ApplyCouponBox productId={contentId} channelPathname={channelPathname} />
    </div>
  )
}
