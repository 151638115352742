import { LEFT_TO_WATCH_SUFFIX, YOU_HAVE_PREFIX } from '@root/utils/window-access/constants'
import { formatLongDuration } from '@root/utils/format-long-duration'

/**
 * Create the you have countdown left to watch string e.g.
 * You have 25 days left to watch
 * @param durationInSeconds
 */
export function youHaveCountdownLeftToWatchString(durationInSeconds: number): string {
  return YOU_HAVE_PREFIX + formatLongDuration(durationInSeconds) + LEFT_TO_WATCH_SUFFIX
}
