import {
  selectChannelPassById,
  selectChannelPropByChannelPathname,
} from '@store/channel-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import { SplitPriceNoFee } from '@library/split-price/split-price-no-fee'
import RenderIf from '@root/utils/render-if'
import { SplitPrice } from '@library/split-price/split-price-fee'
import { useCalculateFees } from '@library/_flows/_checkout-video-flow/_hooks/use-calculate-fees'

export type Props = {
  channelPathname: string
  passId: string
}

export function PassSplitPrice({ channelPathname, passId }: Props): JSX.Element {
  const channelFeeDisplayType =
    useSel(selectChannelPropByChannelPathname(channelPathname)('channelFeeDisplayType')) ??
    'exclusive'
  const pass = useSel(selectChannelPassById(channelPathname, passId))
  const price = pass?.price ?? 0
  const currency = pass?.currency ?? 'nzd'

  const priceTotals = useCalculateFees(price, channelPathname)

  return (
    <>
      <RenderIf isTrue={channelFeeDisplayType === 'inclusive'}>
        <SplitPriceNoFee checkoutTitle={'Pass'} total={price} currency={currency} />
      </RenderIf>
      <RenderIf isTrue={channelFeeDisplayType === 'exclusive'}>
        <SplitPrice
          amount={price}
          vidzingFee={priceTotals.fee}
          total={priceTotals.total}
          currency={currency}
          checkoutTitle={'Price'}
        />
      </RenderIf>
    </>
  )
}
