import { LiveContentScheduleState } from '@root/utils/content-schedule-state'
import {
  LIVE_STREAM_ACCESS_REPLAY_FOR_PREFIX,
  LIVE_STREAM_ACCESS_REPLAY_UNTIL_PREFIX,
  LIVE_STREAM_AFTER_LIVE_STREAM_SUFFIX,
  THREE_DAYS,
} from './constants'
import { formatLongDuration } from '@root/utils/format-long-duration'
import { getLiveExpiryDate } from '@root/utils/live-utils'
import { Temporal } from '@js-temporal/polyfill'
import { dmyFormatter, hmtFormatter } from '@root/utils/date-time-formatters'
import { ContentState } from '@apis/contents-microservice/_entities/_types'
import { addSecondsToCurrentDate, secondsIsGreaterThanDays } from '@root/utils/connascence'
import { youHaveCountdownLeftToWatchString } from '@root/utils/window-access/common-window-access'

interface Props {
  contentState: ContentState
  liveState: LiveContentScheduleState
  liveEndTime: string
  liveReplay: number
}
/**
 * Function to return the window access strings for live content
 * Handles all possible live states pre-sale, live, replay, expired
 * @param contentState
 * @param liveState
 * @param liveEndTime
 * @param liveReplay
 */
export function getLiveWindowAccessString({
  contentState,
  liveState,
  liveEndTime,
  liveReplay,
}: Props): string {
  // Pre-sale and live window access string
  const getPreSaleAndLiveWindowAccessString = (): string => {
    switch (contentState.button) {
      case 'findOutMore':
      case 'subscribe':
      case 'ticketedOnly':
      case 'bookNow':
      case 'buyNow':
        return liveNoAccessPreSaleAndLiveWatchString(liveReplay)
      case 'subscribed':
      case 'booked':
      case 'purchased':
      case 'watchNow':
        return liveHasAccessPreSaleAndLiveWatchString(contentState.access.expiresIn)
      default:
        return null
    }
  }

  // Replay window access string
  const getReplayWindowAccessString = (): string => {
    switch (contentState.button) {
      case 'findOutMore':
      case 'subscribe':
      case 'ticketedOnly':
      case 'bookNow':
      case 'buyNow':
        return liveNoAccessReplayWatchString(liveEndTime, liveReplay)
      case 'subscribed':
      case 'booked':
      case 'purchased':
      case 'watchNow':
        return liveHasAccessReplayWatchString(contentState.access.expiresIn)
      default:
        return null
    }
  }

  switch (liveState) {
    case 'pre-sale':
    case 'live':
      return getPreSaleAndLiveWindowAccessString()
    case 'replay':
      return getReplayWindowAccessString()
    case 'expired':
      return null
  }
}

/**
 * Get live pre-sale and live no access watch string
 * @param liveReplay
 */
function liveNoAccessPreSaleAndLiveWatchString(liveReplay: number): string {
  if (liveReplay) {
    return liveAccessReplayForWindowString(liveReplay)
  }
  return null
}

/**
 * Get live pre-sale and live has access watch string
 * @param accessExpiresInSeconds
 */
function liveHasAccessPreSaleAndLiveWatchString(accessExpiresInSeconds: number): string {
  if (secondsIsGreaterThanDays(accessExpiresInSeconds, THREE_DAYS)) {
    const endDateString = addSecondsToCurrentDate(accessExpiresInSeconds).toISOString()
    return liveAccessReplayUntilString(endDateString)
  }
  return youHaveCountdownLeftToWatchString(accessExpiresInSeconds)
}

/**
 * Get live no access watch string
 * @param liveEndTime
 * @param liveReplay
 */
function liveNoAccessReplayWatchString(liveEndTime: string, liveReplay: number): string {
  const expiryDate = getLiveExpiryDate(liveEndTime, liveReplay).toISOString()
  return liveAccessReplayUntilString(expiryDate)
}

/**
 * Function to return the correct live purchased watch string
 * if days greater than 3 show watch replay until date otherwise
 * show you have countdown left to watch
 * @param accessExpiresInSeconds
 */
function liveHasAccessReplayWatchString(accessExpiresInSeconds: number): string {
  if (secondsIsGreaterThanDays(accessExpiresInSeconds, THREE_DAYS)) {
    const endDateString = addSecondsToCurrentDate(accessExpiresInSeconds).toISOString()
    return liveAccessReplayUntilString(endDateString)
  }
  return youHaveCountdownLeftToWatchString(accessExpiresInSeconds)
}

/**
 * Create the access replay for window watch string e.g.
 * Access replay for 2 days after live stream
 * @param replayInSeconds
 */
function liveAccessReplayForWindowString(replayInSeconds: number): string {
  return (
    LIVE_STREAM_ACCESS_REPLAY_FOR_PREFIX +
    formatLongDuration(replayInSeconds) +
    LIVE_STREAM_AFTER_LIVE_STREAM_SUFFIX
  )
}

/**
 * Create access replay until string e.g.
 * Access replay until 10 Jun 2022 7:30pm NZDT
 * @param dateString
 */
function liveAccessReplayUntilString(dateString: string): string {
  const temporalDate = Temporal.Instant.from(dateString)
  return (
    LIVE_STREAM_ACCESS_REPLAY_UNTIL_PREFIX +
    dmyFormatter.format(temporalDate) +
    ' ' +
    hmtFormatter.format(temporalDate)
  )
}
