import type { DialogParams } from '../../dialog/_types'
import { DIALOG_PARAMS_KEY, NO_DIALOG_PARAMS } from '../../dialog/_constants'

/**
 * This init function has a side effect from session storage.
 * It tries to load DIALOG_PARAMS_KEY from session storage.
 * If DIALOG_PARAMS_KEY exists, the URL matches the current URL,
 * and the dialogType equals 'checkout' or 'subscribe', the dialogue will pop up.
 */
export function initDialogParams(): DialogParams {
  if (typeof window !== 'undefined') {
    // Browser environment
    const dialogParams: DialogParams | null = JSON.parse(
      sessionStorage.getItem(DIALOG_PARAMS_KEY) ?? 'null',
    )

    if (
      dialogParams &&
      dialogParams.dialogType !== 'noDialog' &&
      dialogParams.url === window.location.href
    ) {
      // Subscribe dialog params exist in the session storage AND
      // The url in the dialog params matches current url AND
      // Dialog type is checkout or subscribe
      return dialogParams
    }
  }

  // Node environment OR
  // Dialog params doesn't exist in the session storage OR
  // The url in the dialog params doesn't match current url OR
  // Dialog type isn't checkout or subscribe
  return NO_DIALOG_PARAMS
}
