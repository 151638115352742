import style from './styles.module.scss'
import type { ReactNode } from 'react'
import UseSelectWhiteLabelData from '@hooks/white-label/use-select-white-label-data'
import RenderIf from '@utils/render-if'
import { useAppSelector } from '@root/store'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import { clsx } from 'clsx'

export type Props = {
  children: ReactNode
  channelPathname: string
}

export function CheckoutButtonBox({ children, channelPathname }: Props): JSX.Element {
  // Get white label for removing disclaimer
  const { whiteLabel } = UseSelectWhiteLabelData()
  const isWhiteLabel = !!whiteLabel

  const channelName =
    useAppSelector(selectChannelPropByChannelPathname(channelPathname)('displayName')) ?? ''

  return (
    <div className={clsx(style.wrapper, { [style.toTheRight]: isWhiteLabel })}>
      <div className={style.button}>{children}</div>
      <RenderIf isTrue={!isWhiteLabel}>
        <p className={style.disclaimer}>
          By clicking Checkout, I agree that Vidzing may pass on my information to {channelName}
        </p>
      </RenderIf>
    </div>
  )
}
