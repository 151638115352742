import style from './common.module.scss'
import { ButtonBox } from '../button-box'
import { ChannelBox } from '../channel-box'
import { TicketBox } from './ticket-box'
import { ReactElement, useState } from 'react'
import { PassDialogParams } from '../_types'
import { usePassStatus } from '@hooks/_api-data-loaders/use-pass-status-data'
import { useHidePassPageDialog } from '@components/dialog-provider/dialog/_hooks/use-hide-pass-page-dialog'
import { PassButton } from '@library/_flows/_channel_pass-flow/pass-button'
import { useGtmPageUser } from '@apis/google-analytics/_hooks/use-gtm-page-user'

export type Props = {
  dialogParams: PassDialogParams
  hideDialog: () => void
}

export function PassDialogContent({
  dialogParams: { channelPathname, passId },
  hideDialog,
}: Readonly<Props>): ReactElement {
  const gtmPage = `/${channelPathname}/pass-checkout?pass=${passId}`
  useGtmPageUser(gtmPage)
  usePassStatus(channelPathname, passId)
  useHidePassPageDialog(passId, channelPathname, hideDialog)

  return (
    <div className={style.wrapper}>
      <h2>Confirm order</h2>
      <TicketBox channelPathname={channelPathname} passId={passId} />
      <ButtonBox channelPathname={channelPathname} accessType={'channel'}>
        <PassButton channelPathname={channelPathname} passId={passId} hideDialog={hideDialog} />
      </ButtonBox>
    </div>
  )
}
