import { RootState } from '@root/store'
import { createSelector } from 'reselect'

const cartState = (state: RootState) => state.cart
export const selectCartContentItem = createSelector(
  [cartState, (state, contentId) => contentId],
  (cart, contentId) => {
    return cart.contentItems.find((item) => item.contentId === contentId)
  },
)
