import { createContext, Dispatch, SetStateAction, useContext } from 'react'

export const CheckoutContext = createContext<CheckoutContextInterface>({
  flexiPrice: 0,
  totalPrice: 0,
  isJoinMailingList: false,
  setFlexiPrice: () => {},
  setTotalPrice: () => {},
  setIsJoinMailingList: () => {},
})

export interface CheckoutContextInterface {
  flexiPrice: number
  totalPrice: number
  isJoinMailingList: boolean
  setFlexiPrice: Dispatch<SetStateAction<number>>
  setTotalPrice: Dispatch<SetStateAction<number>>
  setIsJoinMailingList: Dispatch<SetStateAction<boolean>>
}

// An easier way to use the context:
export const useCheckoutContext = (): CheckoutContextInterface => {
  return useContext(CheckoutContext)
}
