import { convertSecondsToMilliSeconds } from '@root/utils/connascence'

export function getLiveExpiryDate(liveEndTime: string, liveReplay: number): Date {
  // Calculate expiry date from live end time and live replay (live replay could be undefined or null
  let expiryDate = new Date(liveEndTime)
  if (liveReplay) {
    expiryDate = new Date(expiryDate.getTime() + convertSecondsToMilliSeconds(liveReplay))
  }

  return expiryDate
}
