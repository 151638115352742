import { DIALOG_PARAMS_KEY } from '../../dialog/_constants'
import type { DialogParams } from '../../dialog/_types'

export function changeDialogParams(
  previousParams: DialogParams,
  nextParams: DialogParams,
): DialogParams {
  sessionStorage.setItem(DIALOG_PARAMS_KEY, JSON.stringify(nextParams))
  return nextParams
}
