// The BE sends the price as a string (EG: NZ$5.00 /mo), so we need to extract the currency from it.
import { toDollar } from '@library/_utilities/to-dollar'

export function getCurrencyFromSubscriptionPriceString(price: string): string | null {
  if (!price) return null

  const regex = /^[^\d]+/
  const matches = price.match(regex)

  return matches ? matches[0].trim() : null
}

// Utility function to format the total amount to pay for a subscription
export function formatTotalToPay(request: {
  interval: string
  formattedCurrency: string
  totalAmountToPayInCents: number
}) {
  const { interval, formattedCurrency, totalAmountToPayInCents } = request

  return `${formattedCurrency}${toDollar(totalAmountToPayInCents)} / ${interval}`
}
