import style from './common.module.css'
import { LinkWrapper } from '../../../_links/link-wrapper'
import useGetSignupUrl from '@hooks/use-get-signup-url'
import { useAppSelector as useSel } from '@root/store'
import { selectChannelThemeByChannelPathname } from '@store/channel-slice/_selectors'

export interface Props {
  channelPathname: string
}

export function ToLoginLink({ channelPathname }: Props): JSX.Element {
  // Get the login Url from hook in case channel is white label
  const { loginUrl } = useGetSignupUrl(channelPathname)

  const channelTheme = useSel(selectChannelThemeByChannelPathname(channelPathname))

  const buttonCssVariables: Record<string, unknown> = {
    '--colorLink': channelTheme?.buttonColour ?? 'var(--white)',
  }

  return (
    <LinkWrapper href={loginUrl}>
      <span className={style.toLogin} style={buttonCssVariables}>
        Log in
      </span>
    </LinkWrapper>
  )
}
