import { contentScheduleState, ContentScheduleState } from 'utils/content-schedule-state'
import { ReactNode } from 'react'
import FormatDateTime from '@features/_ui/format-date-time'
import { dFormatter, dmyFormatter, hmtFormatter } from 'utils/date-time-formatters'
import RelativeDateString from '@features/_ui/content-schedule/relative-date-string'
import Separator from '@features/_ui/separator'

export interface Props {
  bundleStartTime: string
  bundleEndTime: string
  bundleDisplayStartDate?: string
  bundleDisplayEndDate?: string
}
export default function BundleSchedule({
  bundleStartTime,
  bundleEndTime,
  bundleDisplayStartDate,
  bundleDisplayEndDate,
}: Props): JSX.Element {
  // Day, month, year date string
  const dmyDate = (
    <div>
      <FormatDateTime
        dateTimeString={bundleDisplayStartDate ?? bundleStartTime}
        formatter={bundleDisplayStartDate ? dFormatter : dmyFormatter}
      />
      {bundleDisplayStartDate ? <Separator type={'dash'} /> : <Separator type={'dot'} />}
    </div>
  )
  // Hour, minute, timezone date string
  const hmtDate = (
    <FormatDateTime
      dateTimeString={bundleDisplayEndDate ?? bundleStartTime}
      formatter={bundleDisplayEndDate ? dmyFormatter : hmtFormatter}
    />
  )

  const componentMap: Record<ContentScheduleState, ReactNode> = {
    ['pre-sale']: (
      <>
        {!bundleDisplayStartDate && <RelativeDateString>Available</RelativeDateString>}
        <Separator type={'dash'} />
        {dmyDate}
        {hmtDate}
      </>
    ),
    live: bundleDisplayStartDate && bundleDisplayEndDate && (
      <>
        <FormatDateTime dateTimeString={bundleDisplayStartDate} formatter={dFormatter} />
        <Separator type={'dash'} />
        <FormatDateTime dateTimeString={bundleDisplayEndDate} formatter={dmyFormatter} />
      </>
    ),
    expired: (
      <>
        {dmyDate}
        {hmtDate}
      </>
    ),
    premium: <> </>,
    available: <></>,
  }

  return <>{componentMap[contentScheduleState(bundleStartTime, bundleEndTime)]}</>
}
